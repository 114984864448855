<template>
    <div id="update-administrator-page">
        <div class='container-fluid p-3'>
            <button class="btn btn-outline-secondary mb-2" @click="goBack">
                {{ $t('back') }}
            </button>
            <div class="card">
                <div class="card-box">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <label>{{$t('name')}}</label>
                                        </div>
                                        <InputText v-model="entry.name"/>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 mt-2">
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <label>{{$t('email')}}</label>
                                        </div>
                                        <InputText v-model="entry.email"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex justify-content-between">
                                <h3 class="title mt-2">{{ $t('permission') }}</h3>
                            </div>
                            <template v-for="role in this.roles" :key="role">
                                <div class="row" v-if="entry.role_id == role.id">
                                    <div class="col-lg-12 d-flex justify-content-between">
                                        <div class="d-flex">
                                            <div class="mr-30">
                                                <SwitchButton :modelValue="this.roleActives.includes(role.id)" @update:modelValue="updateCheckedRole(role.id)"/> 
                                            </div>
                                            <span class="text-bold">
                                                {{ role.name }}
                                            </span>
                                        </div>
                                        <label class="container-checkbox mb-0">
                                            {{ $t('selectAll') }}
                                            <input type="checkbox" :checked="setCheckAllPermission(role.id) ? true : false" @change="updateCheckedAllPermission(role.id)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <template v-if="this.roleActives.includes(role.id)">
                                        <template v-for="permission in role.permissions" :key="permission">
                                            <div class="col-lg-3 col-md-6 col-sm-12 mt-2">
                                                <label class="container-checkbox">
                                                    <span v-text="getPermissionName(permission.action, permission.name)"></span>
                                                    <input type="checkbox" :checked="checkExitPermission(role.id, permission.id)" @change="updateCheckedPermission(role.id, permission.id)">
                                                    <span class="checkmark-checkbox"></span>
                                                </label>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </template>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <AppPermission :keyPerm="$constant.PERMISSIONS.PER_ADMINISTRATOR" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                        <button type="button" class="btn btn-primary" @click="updateUserPermissions">
                                            <i class="fa fa-save mx-2"></i>
                                            <span class="">{{ $t('save') }}</span>
                                        </button>
                                    </AppPermission>
                                </div>
                            </div>
                            <div class="mb-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat, clone, isChangeData, checkPermission } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr';
import { useRoute } from 'vue-router';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import AppPermission from '@/components/AppPermission.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'UpdateAdministrator',
    setup() {
        return { numberFormat, clone, isChangeData, checkPermission };
    },
    components: {
        InputText,
        SwitchButton,
        AppPermission
    },
    computed: {
    },
    data() {
        return {
            userId: null,
            roles: [],
            entry: {},
            permissions: [],
            roleActives: [],
            checkAllPermissions: [],
        }
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_ADMINISTRATOR, this.$constant.PERMISSIONS.PER_ACTION_UPDATE)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    mounted() {
        const route = useRoute();
        this.userId = route?.params?.id ?? null;
        if(this.userId){
            this.getUser();
            this.getRoleService();
        }
    },
    methods: {
        async getUser(){
            const res = await this.$store.dispatch('permissionStore/getUser', {
                omi_id: this.userId,
                service_id: this.$constant.SERVICE_AFFILIATE_ID
            });
            this.entry = res ?? {};
            this.getPermissionActives();
        },
        getPermissionActives(){
            if(!this.entry){
                return;
            }
            if(this.entry.roles?.length){
                this.entry.roles.forEach(role => {
                    this.roleActives.push(role.id);
                    if(role?.permissions?.length){
                        role?.permissions.forEach(permission => {
                            if (!this.permissions) {
                                this.permissions = {};
                            }
                            if (!this.permissions[role.id]) {
                                this.permissions[role.id] = [];
                            }
                            if(!this.permissions[role.id].includes(permission.id)){
                                this.permissions[role.id].push(permission.id);
                            }
                        })
                    }
                })
            }
        },
        async getRoleService(){
            const res = await this.$store.dispatch('permissionStore/getRoleService', {
                service_id: this.$constant.SERVICE_AFFILIATE_ID
            });
            this.roles = res;
        },
        checkExitPermission(roleId, permissionId){
            if (!this.permissions) {
                return false;
            }
            if (!this.permissions[roleId]) {
                return false;
            }
            if(this.permissions[roleId].includes(permissionId)){
                return true;
            }
            return false
        },
        async updateUserPermissions(){
            let roleSubmits = [];
            this.entry.roles.forEach(r => {
                if(r.service_id != this.$constant.SERVICE_AFFILIATE_ID){
                    roleSubmits.push(r);
                }
            })
            this.roles.forEach(r => {
                let role = {...r};
                if(this.roleActives.includes(r.id)){
                    let permissionSubmits = [];
                    r.permissions.forEach(p => {
                        if(this.permissions[r.id] && this.permissions[r.id].includes(p.id)){
                            permissionSubmits.push(p);
                        }
                    });
                    role.permissions = [...permissionSubmits];
                    roleSubmits.push(role);
                }
            });
            this.entry.roles = [...roleSubmits];
            this.entry.service_id = this.$constant.SERVICE_AFFILIATE_ID;
            const res = await this.$store.dispatch('permissionStore/updateRoleUser', this.entry);
            if(res){
                toastr.success(this.$t('msgUpdateSuccess'));
            }else{
                toastr.success(this.$t('msgUpdateError'));
            }
        },
        goBack(){
            this.$router.go(-1);
        },
        updateCheckedPermission(roleId, permissionId){
            if (!this.permissions) {
                this.permissions = {};
            }
            if (!this.permissions[roleId]) {
                this.permissions[roleId] = [];
            }
            if(!this.permissions[roleId].includes(permissionId)){
                this.permissions[roleId].push(permissionId);
            }else{
                if(this.permissions[roleId]){
                    const index = this.permissions[roleId].findIndex((per) => per == permissionId);
                    if(index !== false){
                        this.permissions[roleId].splice(index, 1);
                    }
                }
            }
        },
        updateCheckedRole(roleId){
            if(!this.roleActives.includes(roleId)){
                this.roleActives.push(roleId);
            }else{
                if(this.roleActives){
                    const index = this.roleActives.findIndex((per) => per == roleId);
                    if(index !== false){
                        this.roleActives.splice(index, 1);
                    }
                }
            }
        },
        getPermissionName(action, name){
            switch (action){
                case 'list':
                    return this.$t('list') + ' ' + name;
                case 'create':
                    return this.$t('create') + ' ' + name;
                case 'update':
                    return this.$t('update') + ' ' + name;
                case 'delete':
                    return this.$t('delete') + ' ' + name;
                default:
                    return name;
            }
        },
        setCheckAllPermission(roleId){
            if(this.checkAllPermissions && this.checkAllPermissions[roleId]){
                return true;
            }
            return false;
        },
        updateCheckedAllPermission(roleId){
            if (!this.permissions) {
                this.permissions = {};
            }
            if (!this.permissions[roleId]) {
                this.permissions[roleId] = [];
            }
            if(!this.checkAllPermissions || !this.checkAllPermissions[roleId]){
                this.checkAllPermissions[roleId] = true;
                this.roles.forEach(role => {
                    if(role.id == roleId && role.permissions){
                        role.permissions.forEach(perm => {
                            this.permissions[roleId].push(perm.id);
                        })
                    }
                })
            }else if(this.checkAllPermissions && this.checkAllPermissions[roleId]){
                this.permissions[roleId] = [];
                this.checkAllPermissions[roleId] = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
#update-administrator-page{
    .mr-30{
        margin-right: 30px;
    }
}
</style>