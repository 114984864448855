<template>
    <div class='modal fade' ref="ModalConfirm" id='ModalConfirm' aria-labelledby='ModalConfirm' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header border-bottom-0'>
                    <button type='button' class='btn-close' @click="hide" aria-label='Close'></button>
                </div>
                <div class='modal-body pt-0'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center' v-if="title">
                            <span class="modal-title">{{ $t(title) }}</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center' v-if="content">
                            <span v-html="$t(content)"></span>
                        </div>
                    </div>
                    <div class='row mt-3' v-if="!this.noButton">
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' data-bs-dismiss='modal' aria-label='Close' @click="confirm">
                                <span v-if="this.button">
                                    {{ $t(button) }}
                                </span>
                                <span v-else>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ModalConfirm",
    props: ['title', 'content', 'button', 'noButton', 'register'],
    data(){
        return {
            type: null,
        }
    },
    methods: {
        confirm(){
            this.$emit('confirm');
        },
        show(type = null){
            this.type = type;
            if(this.register && this.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR){
                let intervalId = setInterval(() => {
                    this.hide();
                    clearInterval(intervalId);
                }, 5000);
            }
            window.jQuery(this.$refs.ModalConfirm).modal('show');
        },
        hide(){
            window.jQuery(this.$refs.ModalConfirm).modal('hide');
            this.redirectLogin();
        },
        redirectLogin(){
            if(this.register && this.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR){
                this.$router.push({name: this.$constant.ROUTER.loginName});
                this.type = null;
            }
        }
    }

}
</script>

<style scoped lang="scss">
@import "../../assets/scss/Modal/ModalConfirm.scss";
</style>
