<template>
    <div id="analytical-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row d-flex justify-content-between'>
                                <div class='col-xl-8 col-md-8 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-4 col-md-4 col-sm-12 text-right'>
                                    {{ $t('lastUpdated') }} <span
                                        v-text="this.lastUpdate ? formatDateCTV(this.lastUpdate, 'HH:mm DD/MM/YYYY') : null"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('analyticalCampaign') }}</h4>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12 col-lg-6 col-xlg-6">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col" colspan="2">{{ $t('openCampaign') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable">
                                                <tr>
                                                    <td>
                                                        <div>{{ this.role_name }}</div>
                                                        <label>{{ numberFormat(this.open?.partner) }}</label>
                                                    </td>
                                                    <td>
                                                        <div>{{ $t('productSold') }}</div>
                                                        <label>{{ numberFormat(this.open?.quantity) }}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>{{ $t('sales') }}</div>
                                                        <label>{{ numberFormat(this.open?.amount) }}đ</label>
                                                    </td>
                                                    <td>
                                                        <div>{{ $t('estCommission') }}</div>
                                                        <label>{{ numberFormat(this.open?.est) }}đ</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-6 col-xlg-6">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col" colspan="2">{{ $t('targetCampaign') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable">
                                                <tr>
                                                    <td>
                                                        <div>{{ this.role_name }}</div>
                                                        <label>{{ numberFormat(this.target?.partner) }}</label>
                                                    </td>
                                                    <td>
                                                        <div>{{ $t('productSold') }}</div>
                                                        <label>{{ numberFormat(this.target?.quantity) }}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div>{{ $t('sales') }}</div>
                                                        <label>{{ numberFormat(this.target?.amount) }}đ</label>
                                                    </td>
                                                    <td>
                                                        <div>{{ $t('estCommission') }}</div>
                                                        <label>{{ numberFormat(this.target?.est) }}đ</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center justify-content-between'>
                                <div>
                                    <h4 class='card-title'>{{ $t('campaignLists') }}</h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportData">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <ul class='nav nav-tabs' role='tablist'>
                                <li class='nav-item'>
                                    <a class='nav-link active' data-bs-toggle='tab' @click="changeTab('open')"
                                        href='#open' role='tab'>
                                        <span class='hidden-sm-up'></span>
                                        <span class='hidden-xs-down'>{{ $t('openCampaign') }}</span>
                                    </a>
                                </li>
                                <li class='nav-item'>
                                    <a class='nav-link' data-bs-toggle='tab' @click="changeTab('target')" href='#target'
                                        role='tab'>
                                        <span class='hidden-sm-up'></span>
                                        <span class='hidden-xs-down'>{{ $t('targetCampaign') }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <!-- Open Campaign Content -->
                                <div class="tab-pane fade show active" id="open">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col">{{ $t('productInformation') }}</th>
                                                    <th scope="col">{{ this.user?.type == this.$constant.PERMISSIONS.TYPE_PARTNER ? $t('numberOfCollaborators') : $t('numberOfPartner') }}</th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('sales')" :field="'amount'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('productSold')" :field="'quantity'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('estCommission')" :field="'total_combined_commission'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('roi')" :field="'ROI'"/>
                                                    </th>
                                                    <th scope="col">{{ $t('action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable" v-if="this.openCampaignProducts.length">
                                                <template v-for="(product) in this.openCampaignProducts"
                                                    :key="product.code">
                                                    <tr>
                                                        <td>
                                                            <div class="info-products d-flex">
                                                                <div class="image">
                                                                    <img v-if="product?.product"
                                                                        :src="product?.product.main_image" />
                                                                    <img v-else :src="image_default" />
                                                                </div>
                                                                <div class="product mx-1">
                                                                    <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                        target="_blank"
                                                                        :href="urlPharmaProduct(product?.product?.slug)"
                                                                        aria-expanded="false">
                                                                        {{ product?.product.name }}
                                                                    </a>
                                                                    <p>
                                                                        {{ $t('skuId') }}: {{
                                                                            product?.product?.product_variant_max_price?.sku
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ numberFormat(product?.total_unique_count) }}</td>
                                                        <td>{{ numberFormat(product?.amount) }}</td>
                                                        <td>{{ numberFormat(product?.quantity) }}</td>
                                                        <td>{{ numberFormat(product?.total_combined_commission) }}</td>
                                                        <td>{{ numberFormat(product?.ROI, 2) }}</td>
                                                        <td class="text-center">
                                                            <span class="icon_action"
                                                                @click="showPerformanceDetail(product?.product.code, product?.product.name)"
                                                                :titleCustom="$t('viewPerformance')"><img
                                                                    :src="icon_view_performance" /></span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="noData" v-if="this.openCampaignProducts?.length == 0">
                                        <span>{{ $t('noData') }}</span>
                                    </div>
                                </div>

                                <!-- Target Campaign Content -->
                                <div class="tab-pane fade" id="target">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col">{{ $t('campaign') }}</th>
                                                    <th scope="col">{{  this.user?.type == this.$constant.PERMISSIONS.TYPE_PARTNER ? $t('collaborator') : $t('partner') }}</th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('sales')" :field="'amount'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('productSold')" :field="'quantity'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('estCommission')" :field="'total_combined_commission'"/>
                                                    </th>
                                                    <th scope="col">
                                                        <TableSortField :current="sortData.field" @onsort="sort" :name="$t('roi')" :field="'ROI'"/>
                                                    </th>
                                                    <th scope="col">{{ $t('action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable" v-if="this.targetCampaignProducts.length">
                                                <template v-for="(entry, index) in targetCampaignProducts" :key="index">
                                                    <tr>
                                                        <td>
                                                            <div class="info-products d-flex">
                                                                <div class="product mx-1">
                                                                    <p>{{ entry?.campaign.name }}</p>
                                                                    <p>{{ $t('code') }}: {{ entry.campaign?.code }}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ numberFormat(entry?.total_unique_count) }}</td>
                                                        <td>{{ numberFormat(entry?.amount) }}</td>
                                                        <td>{{ numberFormat(entry?.quantity) }}</td>
                                                        <td>{{ numberFormat(entry?.total_combined_commission) }}</td>
                                                        <td>{{ numberFormat(entry?.ROI, 2) }}</td>
                                                        <td class="text-center">
                                                            <span class="icon_action"
                                                            @click="showPerformanceDetailTarget(entry?.campaign?.id, 'performance')"
                                                                :titleCustom="$t('viewPerformance')"><img
                                                                    :src="icon_view_performance" /></span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <div id="noData" v-if="this.targetCampaignProducts?.length == 0">
                                            <span>{{ $t('noData') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" />
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, formatDateCTV, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import store from "@/store";
import Paginate from '@/components/Paginate.vue';
import icon_view_performance from '../../../public/images/icon_view_performance.png'
import InputMonth from '@/components/Form/InputMonth.vue'
import TableSortField from '@/components/TableSortField.vue';
import i18n from '@/locales/i18nHelper'
import { mapGetters } from 'vuex';

export default {
    name: 'AnalyticalCampaign',
    setup() {
        return { numberFormat, formatDateCTV, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        Paginate,
        InputMonth,
        TableSortField
    },
    data() {
        return {
            isLoading: false,
            dateTimeSearch: moment().format('YYYY-MM'),
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: null,
            },
            productsActive: [],
            entries: [],
            open: null,
            target: null,
            openCampaignProducts: [],
            targetCampaignProducts: [],
            tab: 'open',
            role: localStorage.getItem('userRoleAffiliate'),
            lastUpdate: null,
            icon_view_performance: icon_view_performance,
            sortData: {
                field: '',
                direction: '',
            },
            role_name: null
        }
    },
    created() {
        this.campaignAnalysis();
        this.campaignsGetOpenAndTarget();
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo',
            userType: 'auth/userType',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_ANALYTICAL_CAMPAIGN, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    watch: {
        dateTimeSearch() {
            this.campaignAnalysis();
            this.campaignsGetOpenAndTarget();
        }
    },
    methods: {
        clearFilter() {
            this.sortData = {
                field: '',
                direction: '',
            }
            this.dateTimeSearch = moment().format('YYYY-MM')
        },
        async changeTab(tab) {
            this.tab = tab;
            this.sortData = {
                field: '',
                direction: '',
            }
            await this.campaignsGetOpenAndTarget();
        },
        showPerformanceDetail(productCode, productName) {
            const originalEncodedProductCode = productCode;
            let openPath = null;
            if (this.userType == this.$constant.PERMISSIONS.TYPE_ADMIN) {
                openPath = `${this.$constant.ROUTER.performanceDetailProductOpen}?product_code=${originalEncodedProductCode}&product_name=${productName}`;
            } else if (this.userType == this.$constant.PERMISSIONS.TYPE_PARTNER) {
                openPath = `${this.$constant.ROUTER.performanceDetailProductOpenPartner}?product_code=${originalEncodedProductCode}&product_name=${productName}`;
            }
            window.location.href = openPath;
        },
        showPerformanceDetailTarget(id, tab)
        {
            if(this.userType == this.$constant.PERMISSIONS.TYPE_ADMIN) {
                this.$router.push({name: this.$constant.ROUTER.detailTargetCampaignName, query: {id: id, tab: tab}});
            } else {
                this.$router.push({name: this.$constant.ROUTER.detailTargetCampaignPartnerName, query: {id: id, tab: tab}});
            }
        },
        async getListProductActiveOpenCampaign() {
            const res = await store.dispatch('productStore/getListProductActiveOpenCampaign', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                // skuId: this.searchData.skuId,
                // productName: this.searchData.productName,
                // categoryId: this.searchData.categoryId,
                // brandId: this.searchData.brandId,
            });

            if (!res) {
                return;
            }
            this.productsActive = res.data;
            this.paginate.currentPage = res.current_page;
            this.paginate.lastPage = res.last_page;
            this.paginate.totalRecord = res.total;
        },

        async getCampaignsTarget() {
            const res = await store.dispatch('campaignStore/getCampaignsTarget', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
            });
            if (res && res.data) {
                this.entries = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },

        async campaignAnalysis() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/campaignAnalysis', {
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
            });
            if (res) {
                this.open = res.open_campaign;
                this.target = res.other_campaigns;
                this.lastUpdate = res.last_update;
                this.role_name = this.userType == this.$constant.PERMISSIONS.TYPE_PARTNER ? i18n.global.t("collaborator") : i18n.global.t("partner")
                
            }
        },

        async campaignsGetOpenAndTarget() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/campaignsGetOpenAndTarget', {
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
                tab: this.tab,
                ...this.sortData
            });
            if (res) {
                if (this.tab == 'open') {
                    this.openCampaignProducts = res;
                } else {
                    this.targetCampaignProducts = res;
                }
            }
        },
        sort(field, direction){
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.campaignsGetOpenAndTarget();
        },
        async exportData(){
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/exportAnalysisCampaign', {
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
                tab: this.tab,
                ...this.sortData
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/analyticalCampaign.scss";
</style>