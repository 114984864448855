<template>
    <div id="analytical-product-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row d-flex justify-content-between'>
                                <div class='col-xl-8 col-md-8 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>
                                    <InputMonth class="mx-2" v-model="filters.time" @update:modelValue="changeTime"/>
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-4 col-md-4 col-sm-12 text-right'>
                                    {{ $t('lastUpdated') }} <span v-text="this.lastUpdate ? formatDateCTV(this.lastUpdate, 'HH:mm DD/MM/YYYY') : null"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{$t('analyticalProduct')}}</h4>
                                </div>
                            </div>
                            <ul class='nav nav-tabs' role='tablist'>
                                <template v-for="(tab, index) in tabs" :key="index">
                                    <li class='nav-item cursor-pointer'>
                                        <a class='nav-link' :class="filters.tab === tab.id ? 'active' : ''" @click="changeTab(tab.id)">
                                            <span class='hidden-sm-up'></span>
                                            <span class='hidden-xs-down'>{{$t(tab?.name)}}</span>
                                        </a>
                                    </li>
                                </template>
                            </ul>
                            <div class="row mt-3">
                                <div class="col-md-12 col-lg-12 col-xlg-12" v-if="this.haveDataAnalysisProduct">
                                    <BarChart :labelValues="this.barLabels" :datasetValues="this.barDatasets"/>
                                </div>
                                <div id="noData" v-else>
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row mb-2'>
                                <div class="col-xl-12 col-md-12 col-sm-12">
                                    <h4 class='card-title'>{{ $t('keyElements') }}</h4>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-12">
                                    <!-- <InputSelect :placeholder="$t('selectProductName')"/> -->
                                    <InputSelectSearch :options="this.products" :optionValue="'code'" :defaultValue="this.filters?.product_code" @searchData="getListProductHaveOrder" @update:modelValue="selectProduct" :placeholder="$t('selectProductName')"/>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('amount')">
                                            <label>{{ $t('sales') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.amount?.value) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.amount?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.amount?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.amount?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('products')">
                                            <label>{{ $t('itemsForSale') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.products?.value) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.products?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.products?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.products?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('orders')">
                                            <label>{{ $t('order') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.orders?.value) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.orders?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.orders?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.orders?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('commissions')">
                                            <label>{{ $t('estCommission') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.commissions?.value) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.commissions?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.commissions?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.commissions?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('ROI')">
                                            <label>{{ $t('roi') }}</label>
                                            <h3 class="text-dark">{{ numberFormatABS(this.mainFactor?.ROI?.value) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.ROI?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.ROI?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.ROI?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('customers')">
                                            <label>{{$t('allBuyer')}}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.customers?.value) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.customers?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.customers?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.customers?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('new_customers')">
                                            <label>{{ $t('newBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.new_customers?.value) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span :class="this.mainFactor?.new_customers?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span v-text="this.mainFactor?.new_customers?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.new_customers?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class='' v-if="!filters.tab">
                                <h4 class='card-title'>{{$t('marketingSourceAnalysis')}}</h4>
                                <div class="d-flex justify-content-center" v-if="this.haveDataMarketingChannel">
                                    <DoughnutChart :labelValues="this.doughnutLabels" :datasetValues="this.doughnutDatasets"/>
                                </div>
                                <div id="noData" v-else>
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>

                            <div class='d-md-flex align-items-center justify-content-between mb-2'>
                                <div>
                                    <h4 class='card-title'>
                                        <span v-if="this.user && this.user.type == this.$constant.PARTNER.type_partner">
                                            {{ $t('topContributor') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('partnerTop') }}
                                        </span>
                                    </h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopPartnerForAnalysisProduct">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ this.$constant.PARTNER.type_partner ==  this.user.type ? $t('contributorInfo') : $t('partnerInfo') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort" :name="$t('sales')" :field="'amount'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort" :name="$t('itemsForSale')" :field="'products'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort" :name="$t('order')" :field="'orders'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort" :name="$t('estCommission')" :field="'commissions'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort" :name="$t('roi')" :field="'ROI'"/>
                                            </th>
                                            <th scope="col">{{ $t('totalBuyer') }}</th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(partner, index) in topPartner" :key="partner">
                                            <tr>
                                                <td class="text-danger text-center text-bold">
                                                    {{ index + 1 }}
                                                </td>
                                                <td>
                                                    <!-- <router-link to="#"> -->
                                                        <span class="text-primary pe-auto" @click="partner?.partner_name ? linkToPartner(partner?.partner_name) : ''">{{ partner?.partner_name }}</span>
                                                    <!-- </router-link> -->
                                                </td>
                                                <td>{{ numberFormat(partner?.amount) }}</td>
                                                <td>{{ numberFormat(partner?.products) }}</td>
                                                <td>{{ numberFormat(partner?.orders) }}</td>
                                                <td>{{ numberFormat(partner?.commissions) }}</td>
                                                <td>{{ numberFormatABS(partner?.ROI) }}</td>
                                                <td>{{ numberFormat(partner?.customers) }}</td>
                                                <td>{{ numberFormat(partner?.new_customers) }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.topPartner?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, numberFormatABS, formatDateCTV, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import BarChart from '@/components/ChartJS/BarChart.vue';
import InputMonth from '@/components/Form/InputMonth.vue'
import moment from 'moment';
import { mapGetters } from 'vuex';
import TableSortField from '@/components/TableSortField.vue';
import InputSelectSearch from '@/components/Form/InputSelectSearch.vue'

export default {
    name: 'AnalyticalProduct',
    setup() {
        return { numberFormat, numberFormatABS, formatDateCTV, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        InputSelect,
        LineChart,
        DoughnutChart,
        BarChart,
        InputMonth,
        TableSortField,
        InputSelectSearch
    },
    data() {
        return {
            isLoading: false,
            tabs: [
                {id: 0, name: 'allSource'},
            ],
            filters: {
                tab: 0,
                time: moment().format('YYYY-MM')
            },
            doughnutLabels: [],
            doughnutDatasets: [],
            haveDataMarketingChannel: false,
            barLabels: [],
            barDatasets: [],
            haveDataAnalysisProduct: false,
            sortData: {
                field: '',
                direction: '',
            },
            lastUpdate: null,
        }
    },
    mounted(){
        this.getChannels();
        this.getDataForAnalysisProduct();
        this.getDataMainFactorForAnalysisProduct();
        this.getDataMarketingChannelForAnalysisProduct();
        this.getDataTopPartnerForAnalysisProduct();
        this.getListProductHaveOrder();
    },
    computed: {
        ...mapGetters({
            mainFactor: 'performanceStore/getDataMainFactorForAnalysisProduct',
            topPartner: 'performanceStore/getDataTopPartnerForAnalysisProduct',
            topProduct: 'performanceStore/getDataTopProductForAnalysisProduct',
            products: 'productStore/products_have_order',
            role: 'auth/userRole',
            user: 'auth/userInfo',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_ANALYTICAL_PRODUCT, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    methods: {
        clearFilter(){
            this.filters = {
                tab: 0,
                time: moment().format('YYYY-MM')
            }
            this.getChannels();
            this.getDataForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
            this.getListProductHaveOrder();
        },
        async getChannels() {
            // const dateTimeSearch = moment(this.filters.time).startOf('month').format('YYYY-MM-DD') + '_' + moment(this.filters.time).endOf('month').format('YYYY-MM-DD')
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannelHasRevenue', {
                dateTimeSearch: this.filters.time,
            });
            if (res) {
                this.tabs = [{id: 0, name: 'allSource'}, ...res];
            }
        },
        changeTab(tab){
            this.filters.tab = tab;
            this.filters.product_code = null;
            this.sortData = {
                field: '',
                direction: '',
            }
            this.getDataForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
            // this.getDataTopProductForAnalysisProduct();
        },
        changeTime(){
            this.filters.tab = 0;
            this.filters.product_code = null;
            this.getChannels();
            this.getDataForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
            this.getListProductHaveOrder();
            // this.getDataTopProductForAnalysisProduct();
        },
        changeType(type){
            this.filters.type = type;
            this.getDataMarketingChannelForAnalysisProduct();
        },
        async getDataForAnalysisProduct(){
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataForAnalysisProduct', this.filters);
            this.barLabels = res?.labels;
            this.barDatasets = res?.datasets;
            this.haveDataAnalysisProduct = res?.have_data;
            this.lastUpdate = res?.last_update;
        },
        async getDataMainFactorForAnalysisProduct(){
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataMainFactorForAnalysisProduct', this.filters);
        },
        async getDataMarketingChannelForAnalysisProduct(){
            if(this.filters.tab){
                return;
            }
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataMarketingChannelForAnalysisProduct', this.filters);
            this.doughnutLabels = res?.labels;
            this.doughnutDatasets = res?.datasets;
            this.haveDataMarketingChannel = res?.have_data;
        },
        async getDataTopPartnerForAnalysisProduct(){
            // if(this.filters.tab){
            //     return;
            // }
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataTopPartnerForAnalysisProduct', {...this.filters, ...this.sortData});
        },
        sort(field, direction){
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.getDataTopPartnerForAnalysisProduct();
            // this.getDataTopProductForAnalysisProduct();
        },
        async getDataTopProductForAnalysisProduct(){
            if(!this.filters.tab){
                return;
            }
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataTopProductForAnalysisProduct', {...this.filters, ...this.sortData});
        },

        async getListProductHaveOrder(keyword = null){
            await this.$store.dispatch('productStore/getListProductHaveOrder', {keyword: keyword, ...this.filters});
        },

        selectProduct(code){
            this.filters.product_code = code;
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
        },
        async exportTopPartnerForAnalysisProduct(){
            // if(this.filters.tab){
            //     return;
            // }
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/exportTopPartnerForAnalysisProduct', {...this.filters, ...this.sortData});
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        async exportTopProductForAnalysisProduct(){
            if(!this.filters.tab){
                return;
            }
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/exportTopProductForAnalysisProduct', {...this.filters, ...this.sortData});
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        linkToPartner(name) {
            if (this.user && this.user.type == this.$constant.PARTNER.type_partner) {
                this.$router.push({ name: this.$constant.ROUTER.collaboratorsName, query: { name: name } });
            } else {
                this.$router.push({ name: this.$constant.ROUTER.partnerName, query: { name: name } });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/analyticalProduct.scss";
</style>