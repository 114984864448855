<template>
    <div id="open-campaign-page">
        <div class='container-fluid p-3'>

            <div class='row mb-3'>
                <div class='form-group row'>
                    <div class="col-xl-8 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterSkuId')" v-model="skuIdLocal"/>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterProductName')" v-model="productNameLocal"/>
                            </div>
                            
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectCategory')" v-model="categoryIdLocal" :options="categories"/>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectBrand')" v-model="brandIdLocal" :options="brands"/>
                            </div>
                        </div>
                    </div>
                    <div class='col-xl-4 col-md-4 col-sm-12'>
                        <button type='submit' class='btn btn-primary mx-2' @click="onSearchData">
                            <span class="mx-2">
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary' @click="clearSearch">
                            <span class="mx-2">
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h5 class='card-title mb-2'>{{ $t('sum') }} : {{ numberFormat(this.paginate.totalRecord) }}</h5>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr> 
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{  $t('estCommission') }}</th>
                                        <th scope="col">{{ $t('collaboratorCommissionRate') }}</th>
                                        <th scope="col">
                                                    <TableSortField @onsort="sort" :defaultDirection="desc" :name="this.$t('numberOfSales')"
                                                        class="description" />
                                                </th>
                                        
                                        <th scope="col">{{ $t('promotionPeriod') }}</th>
                                        <th scope="col">{{ $t('Link') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(entry, index) in listProductLinks" :key="index">
                                        <tr>
                                            <td>
                                                <div class="info-products d-flex" @click="openProductPage(entry?.product_new?.slug || entry?.open_campaign_product?.product_new?.slug)">
                                                    <div class="image">
                                                        <img
                                                            :src="entry?.product_new?.main_image || entry?.open_campaign_product?.product_new?.main_image"/>
                                                    </div>
                                                    <div class="product mx-1" style="max-width: 500px;">
                                                        <p class="text-primary">{{ entry?.open_campaign_product?.product_new?.name || entry?.product_new?.name }}</p>
                                                        <p>
                                                            {{ $t('skuId') }}: {{
                                                            entry?.open_campaign_product?.product_new?.product_variant_max_price?.sku || entry?.product_new?.product_variant_max_price?.sku
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ numberFormat(entry?.open_campaign_product?.product_new?.product_variant_max_price?.price  * ( entry?.commission_rate_contributor / 100) || entry?.product_new?.product_variant_max_price?.price * ( entry?.commission_rate_contributor / 100))  }}</td>
                                            <td class="text-center">{{
                                                entry?.commission_rate_contributor ?? 0 }} %
                                            </td>
                                            <td>
                                                {{ (entry?.open_campaign_product?.product_new?.sale_count ?? 0) || (entry?.product_new?.sale_count ?? 0) }}
                                            </td>
                                            <td>
                                                {{
                                                $filters.formatStartTimeAndEndTime((entry?.open_campaign_product?.start_time || entry?.start_time),
                                                (entry?.open_campaign_product?.end_time || entry?.end_time)) }}
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <!-- <button type="button" class="btn btn-primary dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        Link
                                                    </button> -->
                                                    <button type="button" class="btn btn-primary" @click="showModalEditProduct(entry)">
                                                        Link
                                                    </button>
                                                    <!-- <ul class="dropdown-menu">
                                                        <template
                                                            v-for="(channel, indexChannel) in this.listMarketingChannels"
                                                            :key="indexChannel">
                                                            <li>
                                                                <a @click="renderAndCopyLink(
                                                                    this.userInfo.id,
                                                                    entry?.open_campaign_product?.product_new?.slug || entry?.product_new?.slug,
                                                                    channel.id,
                                                                    entry?.id,
                                                                    entry?.open_campaign_product?.product_new?.name || entry?.product_new?.name,
                                                                    entry?.open_campaign_product?.product_new?.code ||  entry?.product_new?.code,
                                                                    'open'
                                                                )" class="dropdown-item" href="#">{{ channel.name
                                                                    }}
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="listProductLinks?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate v-if="listProductLinks?.length > 0" :value="paginate" :record="this.record" @pageChange="pageChange" />
        </div>
    </div>
    <ModalConfirm :title="$t('confirmRemoveProduct')" :content="$t('contentConfirmRemoveProduct')"
        :button="$t('remove')" @confirm="removeProduct" />
    <ModalCreate />
    <ModalSetCommission />
    <EditDescription ref="EditDescription"/>
</template>

<script>
import { numberFormat, formatDateCTV, generateAffiliateLink, linkWebPharma } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalCreate from '@/components/Modal/OpenCampaign/ModalCreate.vue';
import ModalSetCommission from '@/components/Modal/Collaborator/ModalSetCommission.vue';
import toastr from 'toastr';
import { mapGetters } from 'vuex';
import TableSortField from '@/components/TableSortField.vue';
import EditDescription from '@/components/Modal/Product/EditDescription.vue';

export default {
    name: 'OpenCampaignContributor',
    setup() {
        return { numberFormat, formatDateCTV };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        ModalConfirm,
        ModalCreate,
        ModalSetCommission,
        TableSortField,
        EditDescription
    },
    data() {
        return {
            isLoading: false,
            filters: {
                sku: null,
                name: null,
                sort: null
            },
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 10,
                currentPage: 1,
                lastPage: 1
            },
            listProductLinks: [],
            sortData: {
                field: null,
                direction: null,
            },
            searchData: {
                skuId: null,
                productName: null,
                categoryId: null,
                brandId: null,
            },
            categories: [],
            brands: [],
            skuIdLocal: null,
            productNameLocal: null,
            categoryIdLocal: null,
            brandIdLocal: null,
            listMarketingChannels: []
        }
    },
    created() {
        this.loadData();
        this.loadDataProductLink();
    },
    // watch: {
    //     'paginate.currentPage': function () {
    //         this.loadDataProductLink();
    //     },
    // },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo'
        }),
        // ...mapGetters('marketingChannels', ['listMarketingChannels']),
    },
    methods: {
        async loadData() {
            // const res = await this.$store.dispatch('marketingChannels/fetchMarketingChannels');
            // const res = await this.$store.dispatch('marketingChannels/getListMarketingChannels');
            // if(res){
            //     this.listMarketingChannels = res;
            // }
            const resBranch = await this.$store.dispatch('productStore/getListBrandsFilter');
            const resCategory = await this.$store.dispatch('productStore/getListCategoryFilter');
            this.categories = resCategory;
            this.brands = resBranch;
        },
        async loadDataProductLink() {
            this.filters.sort = this.sortData.direction
            const productLinks = await this.$store.dispatch('productLinks/fetchProductLinks', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                sku: this.searchData.skuId,
                name: this.searchData.productName,
                categoryId: this.searchData.categoryId,
                brandId: this.searchData.brandId,
                sort: this.filters.sort
            });
            this.paginate.currentPage = productLinks.current_page;
            this.paginate.lastPage = productLinks.last_page;
            this.paginate.totalRecord = productLinks.total;
            this.listProductLinks = productLinks.data; 
        },
        renderAndCopyLink(contributor_id, slug, channel_id, open_campaign_contributor_id, name, code, type) {
            const link = generateAffiliateLink(slug, contributor_id, channel_id, 1);
            navigator.clipboard.writeText(link).then(() => {
                // this.save(open_campaign_contributor_id, link, channel_id, code, type);
                toastr.success('Đã copy link giới thiệu của <br>' + '<b>' + name + '</b>');
            }).catch(err => {
                toastr.error('Không thể sao chép liên kết');
            });
        },
        async save(open_campaign_contributor_id, link, marketing_channel_id, code, type) {
            const params = {
                open_campaign_contributor_id: open_campaign_contributor_id,
                url: link,
                marketing_channel_id: marketing_channel_id,
                product_code: code,
                type: type
            };
            await this.$store.dispatch('productLinks/createProductLinkChannel', params);
        },
        clearSearch() {
            this.skuIdLocal = null;
            this.productNameLocal = null;
            this.categoryIdLocal = null;
            this.brandIdLocal = null;
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];
            this.paginate.currentPage = 1;
            this.searchData.skuId = null;
            this.searchData.productName = null;
            this.searchData.categoryId = null;
            this.searchData.brandId = null;
            this.loadDataProductLink();
        },
        onSearchData() {            
            this.searchData.skuId = this.skuIdLocal;
            this.searchData.productName = this.productNameLocal;
            this.searchData.categoryId = this.categoryIdLocal;
            this.searchData.brandId = this.brandIdLocal;   
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];   
            this.paginate.currentPage = 1;                     
            this.loadDataProductLink();
        },
        pageChange(page) {
            this.paginate.currentPage = page;
            this.loadDataProductLink();
        },
        async sort(field, direction) {
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.loadDataProductLink(this.sortData.field, this.sortData.direction);
        },
        openProductPage(slug) {
            linkWebPharma(slug);
        },
        showModalEditProduct(entry){
            if(!entry){
                return;
            }
            let productLinkDetail = {
                campaign_id: this.$constant.CAMPAIGN.open_campaign_id,
                slug: (entry?.open_campaign_product?.product_new?.slug || entry?.product_new?.slug) ?? null,
            };
            this.$store.dispatch('productLinks/setProductLinkDetail', productLinkDetail);
            this.$refs.EditDescription.show();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/openCampaign.scss";
</style>