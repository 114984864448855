<template>
    <div id="orders-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='row'>
                    <div class="col-xl-10 col-md-9 col-sm-12">
                        <div class="row">
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2">
                                <InputText :placeholder="$t('orderCode')" v-model="filters.keyword"/>
                            </div>
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR">
                                <InputText :placeholder="$t('partnerOrContributorName')" v-model="filters.partner_name"/>
                            </div>
                            <div class="col-xl-3 col-md-4 col-sm-12 mb-2">
                                <Daterangepicker :placeholder="$t('orderDate')" v-model="filters.order_date"/>
                            </div>
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR">
                                <InputSelect :placeholder="$t('accountType')" :options="this.$constant.ACCOUNT_TYPE" v-model="filters.type"/>
                            </div>
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('campaignType')" :options="this.$constant.CAMPAIGN.listCampaignType" v-model="filters.campaign_type"/>
                            </div>
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('marketingChannels')" :options="this.listMarketingChannel" v-model="filters.marketing_channel"/>
                            </div>
                            <div class="col-xl-2 col-md-4 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('paymentStatus')" :options="this.$constant.PAYMENT.listStatus" v-model="filters.status_payment"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <button type='submit' class='btn btn-success mx-2' @click="search">
                            <span>
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary' @click="clearFilter">
                            <span>
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="card-body px-0 d-flex flex-wrap pt-0">
                            <div class='col-xl-10 col-md-10 col-sm-12 container-status'>
                                <template v-for="(status, index) in statusLists" :key="index">
                                    <button class="btn" :class="status.id === filters.status ? 'border-bottom__custom' : ''" @click="changeFilterStatus(status.id)">
                                        <span class="card-title">
                                            {{ $t(status.label) }}
                                        </span>
                                    </button>
                                </template>
                            </div>
                            <div class='col-xl-2 col-md-2 col-sm-12 d-flex justify-content-end'>
                                <button type='submit' class='btn btn-outline-secondary' @click="exportData()">
                                    <span>
                                        {{ $t('exportData') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('orderCode') }}</th>
                                        <th scope="col">{{ $t('orderDate') }}</th>
                                        <th scope="col" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR">
                                            <span v-if="userType == this.$constant.PERMISSIONS.TYPE_ADMIN || userType == this.$constant.PERMISSIONS.TYPE_BUSSINESS">
                                                {{ $t('partnerOrContributorName') }}
                                            </span>
                                            <span v-if="userType == this.$constant.PERMISSIONS.TYPE_PARTNER">
                                                {{ $t('collaborator') }}
                                            </span>
                                        </th>
                                        <th scope="col" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR">
                                            <span v-if="userType == this.$constant.PERMISSIONS.TYPE_ADMIN || userType == this.$constant.PERMISSIONS.TYPE_BUSSINESS">
                                                {{ $t('campaignCode') }}
                                            </span>
                                            <span v-if="userType == this.$constant.PERMISSIONS.TYPE_PARTNER">
                                                {{ $t('commissionCode') }}
                                            </span>
                                        </th>
                                        <th scope="col">{{ $t('marketingChannels') }}</th>
                                        <th scope="col">{{ $t('campaignType') }}</th>
                                        <th scope="col">{{ $t('orderValues') }}</th>
                                        <th scope="col">{{ $t('orderStatus') }}</th>
                                        <th scope="col">{{ $t('commissionValue') }}</th>
                                        <th scope="col">{{ $t('paymentStatus') }}</th>
                                        <th scope="col" class="text-center">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="entry in entries" :key="entry">
                                        <tr>
                                            <td v-text="entry.code"></td>
                                            <td v-text="entry.order_date ? formatDateCTV(entry.order_date, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="entry.partner_name" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR"></td>
                                            <td v-text="entry.campaign_code" v-if="userType != this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR"></td>
                                            <td v-text="entry.marketing_channel"></td>
                                            <td v-text="entry.campaign_type"></td>
                                            <td v-text="numberFormat(entry.amount)"></td>
                                            <td v-text="entry.order_status"></td>
                                            <td v-text="numberFormat(entry.commission_value)"></td>
                                            <td v-text="entry.status_payment"></td>
                                            <td class="text-center">
                                                <span>
                                                    <router-link :to="`${this.$constant.ROUTER.orderDetail.replace(':id', entry.id)}`" class="text-dark">
                                                        <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye mx-2"></i></span>
                                                    </router-link>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="filters.record" @pageChange="pageChange" v-if="this.entries?.length > 0"/>
        </div>
        <ModalDetailPartner/>
        <ModalRemovePartner/>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, formatDateCTV, clone, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import ModalDetailPartner from '@/components/Modal/Partner/ModalPartnerDetail.vue';
import ModalRemovePartner from '@/components/Modal/Partner/ModalRemovePartner.vue';
import { mapGetters } from 'vuex';
import InputSelect from '@/components/Form/InputSelect.vue';

export default {
    name: 'Orders',
    setup() {
        return { numberFormat, formatDateCTV, clone, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        SwitchButton,
        ModalDetailPartner,
        ModalRemovePartner,
        InputSelect
    },
    data() {
        return {
            isLoading: false,
            record: 100,
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            statusLists: this.$constant.ORDER.statusListFilter,
            filters: {
                status: null,
                record: this.$constant.PAGE.record
            }
        }
    },
    mounted(){
        this.loadData();
        this.fetchMarketingChannels();
    },
    computed: {
        ...mapGetters({
            entries: 'orderStore/listOrder',
            listMarketingChannel: 'marketingChannels/listMarketingChannels',
            role: 'auth/userRole',
            user: 'auth/userInfo',
            userType: 'auth/userType',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_ORDERS, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    methods: {
        changeFilterStatus(status){
            this.filters.status = status;
            this.loadData();
        },
        async fetchMarketingChannels() {
            await this.$store.dispatch('marketingChannels/fetchMarketingChannels');
        },
        async loadData(){
           
            const res = await this.$store.dispatch('orderStore/fetchOrderLink', this.filters);
            this.paginate.totalRecord = res?.result?.data?.total;
            this.paginate.currentPage = res?.result?.data?.current_page;
            this.paginate.lastPage = res?.result?.data?.last_page;
        },
        async exportData(){
            
            const res = await this.$store.dispatch('orderStore/exportOrder', this.filters);
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        pageChange(page) {
            this.filters.page = page
            this.loadData()
        },
        search(){
            this.filters.page = 1
            this.loadData()
        },
        clearFilter(){
            this.filters = {
                status: null,
                type: null,
                campaign_type: null,
                marketing_channel: null,
                status_payment: null,
                record: this.$constant.PAGE.record,
                partner_name: null
            };
            this.loadData()
        }
    },
    watch: {
        user: {
            handler: function(newData) {                
                if(newData){
                    this.loadData();
                }
            },
            deep: true
        },
    },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/partner.scss";
</style>