<template>
    <div id="register-page">
        <div class="row justify-content-center">
            <div class="register-page__form">
                <div class="logo">
                    <img src="../../../public/logo.svg" />
                </div>
                <div class="form-title">
                    <h3 class="">{{ $t('register') }}</h3>
                    <span>{{ $t('haveNotAccount') }}
                        <router-link :to="this.$constant.ROUTER.login" class="text-green text-decoration-none">
                            <label>
                                {{ $t('login') }}
                            </label>
                        </router-link>
                    </span>
                </div>
                <div class="row content p-3">
                    <div class="col-xl-7 col-md-7 col-sm-12">
                        <img src="../../../public/logo.png" class="w-100" />
                    </div>
                    <div class="col-xl-5 col-md-5 col-sm-12">
                        <form @submit="register">
                            <div class="form-content">
                                <div class="form-group">
                                    <label>{{ $t('fullname') }}</label>
                                    <InputText :placeholder="$t('enterFullname')" v-model="model.name" :errors="this.errors.name ?? []" :readonly="this.noCheckAccount"/>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('phone') }}</label>
                                    <InputText :placeholder="$t('enterPhone')" v-model="model.phone" :errors="this.errors.phone ?? []" :readonly="this.noCheckAccount"/>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('emailAdress') }}</label>
                                    <InputText :placeholder="$t('enterEmail')" v-model="model.email" :errors="this.errors.email ?? []"/>
                                    <span class="text-danger" v-if="this.noCheckAccount">{{ $t('accountRegisteredPharma') }}</span>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('verifyCodeEmail') }}</label>
                                    <div class="send-code__group">
                                        <InputText :placeholder="$t('enterVerifyCode')" v-model="model.verify_code" :errors="this.errors.verify_code ?? []" class="register-form__input input-send-code"/>
                                        <button class="btn btn-success btn-send-code" type="button" @click="sendCode">{{ $t('sendCode') }}</button>
                                    </div>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('password') }}</label>
                                    <InputText :placeholder="$t('enterPassword')" v-model="model.password" :append="true" :type="this.showPassword ? 'text' : 'password'" :errors="this.errors.password ?? []" :readonly="this.noCheckAccount"/>
                                    <i :class="this.showPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showPassword')"></i>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('confirmPassword') }}</label>
                                    <InputText :placeholder="$t('confirmPassword')" v-model="model.confirm_password" :append="true" :type="this.showConfirmPassword ? 'text' : 'password'" :errors="this.errors.confirm_password ?? []" :readonly="this.noCheckAccount"/>
                                    <i :class="this.showConfirmPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showConfirmPassword')"></i>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('accountType') }}: </label>
                                    <InputSelect :options="this.$constant.ACCOUNT_TYPE" v-model="model.type"/>
                                </div>
                                <div class="form-group text-bold">
                                    <div id="input-checkbox__form">
                                        <div class="row d-flex">
                                            <div class="col-12 d-flex align-items-center">
                                                <label class="container-checkbox ml-2 w-100 text-align-justify">
                                                    {{ $t('confirmRegister') }}
                                                    <span class="text-green">
                                                        {{ $t('term') }}
                                                    </span>
                                                    <input type="checkbox" class="mr-2" v-model="confirm">
                                                    <span class="checkmark-checkbox"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary" :disabled="!confirm ? true : false">{{ $t('register') }}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" :content="this.content" :noButton="true" :register="true"/>
</template>

<script>
import InputSelect from '@/components/Form/InputSelect.vue';
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
export default {
    name: 'RegisterScreen',
    components: { InputSelect, InputText, ModalConfirm},
    data() {
        return {
            model: {
                name: '',
                phone: '',
                email: '',
                verify_code: '',
                password: '',
                confirm_password: '',
                type: this.$constant.PARTNER.type_colloborator,
                commit: false,
            },
            showPassword: false,
            showConfirmPassword: false,
            errors: {},
            content: null,
            noCheckAccount: false,
            confirm: false,
        }
    },
    mounted() {
    },
    methods: {
        clearData(){
            this.model = {
                name: '',
                phone: '',
                email: '',
                verify_code: '',
                password: '',
                confirm_password: '',
                type: 1,
                commit: false,
            }
            this.noCheckAccount = false;
        },
        togglePasswordType(item) {
            this[item] = !this[item]
        },
        async sendCode(){
            this.noCheckAccount = false;
            this.errors = {};
            let params = {
                "type": "email",
                "email": this.model.email,
                "verify_type": "register"
            }
            const res = await this.$store.dispatch('auth/verify', params);
            if(res?.code === 500 && res?.error){
                if(res?.message){
                    toastr.error(res?.message)
                }else if(res?.messages){
                    this.errors = res?.messages;
                }
                return;
            }else if(res?.code === 200){
                this.noCheckAccount = res?.data?.length > 0 ? true : false;
                toastr.success(res?.message)
            }
        },
        async register(e){
            e.preventDefault();
            if(!this.confirm){
                return;
            }
            this.errors = {};
            const res = await this.$store.dispatch('auth/register', {...this.model, noCheckAccount: this.noCheckAccount});
            if(res?.code === 500 && res?.error){
                this.errors = res?.messages;
                return;
            }
            if(res?.code === 200){
                this.content = this.model.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR ? this.$t('registerContributorSuccess') : this.$t('registerSuccess');
                this.$refs.ModalConfirm.show(this.model.type);
                this.clearData();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/register.scss";
</style>