import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface PermisionState {
    roles: [],
    users: [],
}

const state: PermisionState = {
    roles: [],
    users: [],
};

const permissionStore: Module<PermisionState, any> = {
    namespaced: true,
    state,
    mutations: {
        setListRoles(state, roles: []) {
            state.roles = roles;
        },
        setUsers(state, users: []) {
            state.users = users;
        },
    },
    actions: {
        async getListRoles({ commit }, params) {
            try {
                const response:any = await http.get(Networks.permission.get_list_roles, {params: params});
                if(response?.data){
                    commit('setListRoles', response?.data?.data)
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getListUsers({ commit }, params) {
            try {
                const response:any = await http.get(Networks.permission.get_many_user, {params: params});
                if(response?.data){
                    commit('setUsers', response?.data?.data)
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getOneRole({ commit }, params) {
            try {
                const response:any = await http.get(Networks.permission.get_one_role, {params: params});
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateRole({ commit }, params) {
            try {
                const response:any = await http.put(Networks.permission.update_role, params);
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateRolePermission({ commit }, params) {
            try {
                const response:any = await http.put(Networks.permission.update_role_permission, params);
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getUser({ commit }, params) {
            try {
                const response:any = await http.get(Networks.permission.get_user, {params: params});
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getRoleService({ commit }, params) {
            try {
                const response:any = await http.get(Networks.permission.get_role_service, {params: params});
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateRoleUser({ commit }, params) {
            try {
                const response:any = await http.post(Networks.permission.update_role_user, params);
                if(response?.code == 200 && response?.data){
                    return response?.data;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        
    },
    getters: {
        getListRoles(state): [] {
            return state.roles;
        },
        getListUsers(state): [] {
            return state.users;
        }
    },
};

export default permissionStore;
