<template>
    <div class='modal fade' ref="ModalDetailPartner" id='ModalDetailPartner' aria-labelledby='ModalDetailPartner' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('detailInformationPartner') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('partnerName') }}:</div>
                                <div class='right' v-text="entry?.name"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('birthday') }}:</div>
                                <div class='right' v-text="entry?.birthday ? formatDateCTV(entry?.birthday, 'DD/MM/YYYY') : null"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('email') }}:</div>
                                <div class='right' v-text="entry?.email"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('phone') }}:</div>
                                <div class='right' v-text="entry?.phone"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('taxCode') }}:</div>
                                <div class='right' v-text="entry?.tax_code"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('address') }}:</div>
                                <div class='right' v-text="entry?.address"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('identification') }}:</div>
                                <div class='right' v-text="entry?.identification_code"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('accountType') }}:</div>
                                <div class='right' v-text="getTypePartnerName(entry.type)"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('identificationFrontImage') }}:</div>
                                <div class='right'>
                                    <span v-if="!entry?.identification_card_front">{{ $t('noHave') }}</span>
                                    <InputFile v-else :image="true" :readonly="true" :modelValue="entry?.identification_card_front"/>
                                </div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('identificationBackImage') }}:</div>
                                <div class='right'>
                                    <span v-if="!entry?.identification_card_front">{{ $t('noHave') }}</span>
                                    <InputFile v-else :image="true" :readonly="true" :modelValue="entry?.identification_card_back"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <span class='title'>{{ $t('paymentInfor') }}</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('bankName') }}:</div>
                                <div class='right' v-text="entry?.bank_name"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('branch') }}:</div>
                                <div class='right' v-text="entry?.bank_branch"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('accountNumber') }}:</div>
                                <div class='right' v-text="entry?.bank_account_number"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('accountOwner') }}:</div>
                                <div class='right' v-text="entry?.bank_owner_name"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('paymentCycle') }}:</div>
                                <div class='right' v-text="getPaymentCycleName(entry?.payment_cycle)"></div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('joiningDate') }}:</div>
                                <div class='right' v-text="entry?.active ? formatDateCTV(entry?.active, 'DD/MM/YYYY') : null"></div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class='info mt-1 d-flex'>
                                <div class='left'>{{ $t('status') }}:</div>
                                <div class='right' v-text="getStatusPartnerName(entry?.status)"></div>
                            </div>
                        </div>
                    </div>

                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PARTNER" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                <button class='btn btn-primary mx-2' @click="updatePartner(entry)">
                                    <span >
                                        {{ $t('edit') }}
                                    </span>
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { clone, getStatusPartnerName, getTypePartnerName, getPaymentCycleName, formatDateCTV } from '@/utils/utils';
import InputFile from '@/components/Form/InputFile.vue';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalPartnerDetail",
    components: {
        InputFile,
        AppPermission
    },
    setup(){
        return {
            getStatusPartnerName, getTypePartnerName, getPaymentCycleName, formatDateCTV
        }
    },
    data(){
        return {
            entry: {}
        }
    },
    methods: {
        show(entry = null){
            this.entry = {}
            if(!entry){
                return;
            }
            this.entry = clone(entry);
            window.jQuery(this.$refs.ModalDetailPartner).modal('show');
        },
        updatePartner(entry){
            this.$store.dispatch('partners/setDetail', entry);
            this.$router.push({name: this.$constant.ROUTER.editPartnerName});
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalPartnerDetail.scss";
</style>
