<template>
    <div id="create-target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row border-block p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <ul class='nav nav-tabs' role='tablist'>
                            <li class='nav-item'>
                                <a class='nav-link' :class="tab === 1 ? 'active' : ''" data-bs-toggle='tab'
                                    role='button' @click="changeTab(1)">
                                    <span class='hidden-sm-up'></span>
                                    <span class='hidden-xs-down'>{{ $t('campaign') }}</span>
                                </a>
                            </li>
                            <li class='nav-item'>
                                <a class='nav-link' :class="tab === 2 ? 'active' : ''" data-bs-toggle='tab'
                                    role='button' @click="changeTab(2)">
                                    <span class='hidden-sm-up'></span>
                                    <span class='hidden-xs-down'>{{ $t('performance') }}</span>
                                </a>
                            </li>
                        </ul>

                        <template v-if="tab == 1">
                            <div class="mt-3 d-flex justify-content-between">
                                <div class="col-lg-4">{{ $t('campaignCode') }}: {{ info.code }}</div>
                                <div class="col-lg-4">{{ $t('campaignStatus') }}:
                                    <span
                                        :class="getClassForTargetCampaignStatus($filters.formatStatusCampaignPartner(info?.status, info.start_time, info.end_time))">
                                        {{ $filters.formatStatusCampaignPartner(info?.status, info.start_time,
                                            info.end_time) }}
                                    </span>
                                </div>
                                <div class="col-lg-4 d-flex justify-content-end">
                                    <button class="btn btn-primary"
                                        v-if="!listStatusCannotEdit.includes($filters.formatStatusCampaignPartner(info.status, info.start_time, info.end_time)) && !this.targetCampaignPartnerInfo?.status">
                                        <router-link
                                            :to="`${this.$constant.ROUTER.updateCampaignPartner.replace(':id', this.currentId)}`"
                                            class="text-white">
                                            <i class="fa fa-edit mx-2"></i>
                                            <span>
                                                {{ $t('campaignEdit') }}
                                            </span>
                                        </router-link>
                                    </button>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="mt-3 d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <!-- Thêm d-flex và align-items-center để căn chỉnh nội dung -->
                                    <span v-html="$t('timeSeeData')"></span>
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class="text-right">
                                    {{ $t('lastUpdated') }} <span v-text="this.last_update ? formatDateCTV(this.last_update, 'HH:mm DD-MM-YYYY') : null"></span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="tab === 1">
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <div class="d-flex justify-content-between">
                                <h4 class='card-title mb-2'>{{ $t('contributorInfo') }}</h4>
                            </div>
                            <div class="table-responsive" v-if="contributors?.length">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('collaboratorName') }}</th>
                                            <th scope="col">{{ $t('numberOfClicked') }}</th>
                                            <th scope="col">{{ $t('numberOfOrders') }}</th>
                                            <th scope="col">{{ $t('estCommission') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(contributor, index) in contributors" :key="index">
                                            <tr>
                                                <td>
                                                    <p>{{ contributor.name }}</p>
                                                </td>
                                                <td>{{ numberFormat(contributor.quantity_click) }}</td>
                                                <td>{{ numberFormat(contributor.quantity_order) }}</td>
                                                <td>
                                                    <!-- <span v-if="contributor.action">
                                                        <i @click="showModalConfirmDeletePartner(index, contributor.name)"
                                                            class="fa fa-trash-alt"></i>
                                                    </span> -->
                                                    {{ numberFormat(contributor.est_commission) }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div id="noData" v-if="this.contributors?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <h4 class='card-title mb-2'>{{ $t('infoProduct') }}</h4>
                            <div class="table-responsive table-products">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border thead-fixed">
                                        <tr>
                                            <th scope="col">{{ $t('product') }}</th>
                                            <th scope="col">{{ $t('price') }}</th>
                                            <th scope="col">{{ $t('distributorCommissionRate') }}</th>
                                            <th scope="col">{{ $t('collaboratorCommissionRate') }}</th>
                                            <th scope="col">{{ $t('Link') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(product, index) in products" :key="index">
                                            <tr>
                                                <td class="col-5">
                                                    <div class="info-products d-flex">
                                                        <div class="image">
                                                            <img :src="product?.product?.main_image" />
                                                        </div>
                                                        <div class="product mx-1">
                                                            <p>{{ product?.product?.name }}</p>
                                                            <p>
                                                                {{ $t('skuIdCode') }}: {{
                                                                product?.product?.product_variant_max_price.sku }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="col-2">
                                                    <span v-if="product?.product?.product_variant_max_price.price">{{
                                                        $filters.formatPrice(product?.product?.product_variant_max_price.price)
                                                        }}</span>
                                                    <span v-else>0</span>
                                                </td>
                                                <td class="col-2">{{ product?.commission_rate_partner }} %</td>
                                                <td class="col-3">
                                                    {{ product?.commission_rate_contributor ?? 0 }} %
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-primary" @click="showModalEditProduct(product)">
                                                            Link
                                                        </button>
                                                        <!-- <ul class="dropdown-menu">
                                                            <template
                                                                v-for="(channel, indexChannel) in this.listMarketingChannels"
                                                                :key="indexChannel">
                                                                <li
                                                                    @click="renderAndCopyLink(product, channel, 'open')">
                                                                    <a class="dropdown-item" href="#">{{
                                                                        channel.name }}</a>
                                                                </li>
                                                            </template>
                                                        </ul> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <h4 class='card-title mb-2'>{{ $t('infoInvitation') }}</h4>
                            <div class="mx-0 p-3">
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('campaignName') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <span>{{ info.name }}</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('campaignTime') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <div class='row'>
                                            <div class='col-lg-6 col-md-12 col-sm-12'>
                                                <span>
                                                    {{ $filters.formatStartTimeAndEndTime(info.start_time, info.end_time
                                                    ) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('message') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <span>
                                            {{ info.messages }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <TargetCampaignPartnerAnalysis :dateTimeSearch="dateTimeSearch" />
            </template>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <EditDescription ref="EditDescription"/>
</template>

<script>
import { numberFormat, generateAffiliateLink, getClassForTargetCampaignStatus, formatDateCTV } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import store from "@/store";
import toastr from 'toastr';
import { mapGetters } from 'vuex';
import TargetCampaignPartnerAnalysis from '../Performance/TargetCampaignPartnerAnalysis.vue';
import InputMonth from '@/components/Form/InputMonth.vue';
import EditDescription from '@/components/Modal/Product/EditDescription.vue';

export default {
    name: 'DetailTargetCampaign',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus, formatDateCTV };
    },
    components: {
        Loading,
        TargetCampaignPartnerAnalysis,
        InputMonth,
        EditDescription
    },
    data() {
        return {
            currentId: null, 
            partners: [],
            tab: 1,
            dateTimeSearch: moment().format('YYYY-MM'),
            dateTimeSearchDefault: moment().format('YYYY-MM'),
            info: {
                name: null,
                start_time: null,
                end_time: null,
                messages: null,
                status: null,
                code: null,
            },
            listMarketingChannels: [],
            contributors: [],
            listStatusCannotEdit: [
                this.$t('campaignStatusClose'),
                this.$t('campaignEnd'),
            ]
        }
    },
    created() {
        
        this.loadData();
        this.getIdDetail();
        this.getDetailCampaign();
    },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo',
            last_update: 'performanceStore/getLastUpdate'
        }),
        targetCampaignPartnerInfo() {
            if (this.partners) {
                return this.partners.find(item => item.partner_id == this.userInfo.id)
            }  
        }
    },
    methods: {
        clearFilter(){
            if(this.dateTimeSearchDefault != this.dateTimeSearch){
                this.dateTimeSearch = moment().format('YYYY-MM');
                return;
            }
            this.$store.dispatch('setClearFilter', true);
        },
        campaignEdit() {            
            // this.$router.push({path: this.$constant.ROUTER.updateCampaignPartner, query: {id: this.currentId}});
        },
        async getIdDetail() {            
            this.currentId = this.$route.query.id ?? null;
            this.tab = this.$route.query.tab == 'performance' ? 2 : 1;
        },
        async loadData() {
            // const res = await this.$store.dispatch('marketingChannels/getListMarketingChannels');
            // this.listMarketingChannels = res
        },
        changeTab(tab) {
            this.tab = tab;
        },
        async getDetailCampaign() {
            const res = await store.dispatch('campaignStore/getDetailCampaign', {id: this.currentId,});
            if (!res || !res.code) {
                return;
            }
            this.partners = res.target_campaign_partners;
            this.products = res.target_campaign_products;
            this.info.name = res.name;
            this.info.start_time = res.start_time;
            this.info.end_time = res.end_time;
            this.info.messages = this.targetCampaignPartnerInfo?.message || res.messages || null;
            // this.info.messages = res.messages;
            this.info.status = res.status;
            this.info.code = res.code;
            this.formatContributors(res.target_campaign_contributors);
            this.products = this.mapProductWithFirstRate(this.products, res.target_campaign_contributors);
        },
        mapProductWithFirstRate(products, contributors) {
            return products?.map(product => {
                const firstContributor = contributors?.find(contributor =>
                    contributor.target_campaign_product_id === product.id
                );
                return {
                    ...product,
                    commission_rate_contributor: firstContributor ? firstContributor.commission_rate_contributor : null
                };
            });
        },
        formatContributors(contributors) {
            this.contributors = contributors?.reduce((acc, item) => {
                const existing = acc.find(entry => entry.id === item.user_id);
                if (!existing) {
                    acc.push({
                        id: item.user_id,
                        name: item.name,
                        partner_id: this.targetCampaignPartnerInfo?.partner_id,
                        quantity_click: item.quantity_click ?? '',
                        quantity_order: item.quantity_order ?? '',
                        est_commission: item.est_commission ?? '',
                        action: false
                    });
                }
                return acc;
            }, []);
        },
        renderAndCopyLink(productActive, channel, type) {
            const link = generateAffiliateLink(productActive?.product?.slug, this.userInfo?.id, channel.id, this.currentId);
            navigator.clipboard.writeText(link).then(() => {
                // this.save(url, channel, type);
                toastr.success('Đã copy link giới thiệu của <br>' + '<b>' + productActive?.product?.name + '</b>');
            }).catch(err => {
                console.error('Could not copy link: ', err);
                toastr.error('Không thể sao chép liên kết');
            });
        },
        showModalEditProduct(entry){
            if(!entry){
                return;
            }
            let productLinkDetail = {
                campaign_id: this.currentId,
                slug: entry?.product?.slug ?? null,
            };
            this.$store.dispatch('productLinks/setProductLinkDetail', productLinkDetail);
            this.$refs.EditDescription.show();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>