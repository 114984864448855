import { createStore } from 'vuex'
import productStore from './modules/productStore'
import campaignStore from './modules/campaignStore'
import marketingChannels from './modules/marketingChannelStore'
import auth from './modules/authStore'
import userStore from './modules/userStore'
import partners from './modules/partnerStore'
import productLinks from './modules/productLinkStore'
import payment from './modules/paymentStore'
import uploadFile from './modules/uploadStore'
import collaborators from './modules/collaboratorStore'
import exportStore from './modules/exportStore'
import orderStore from './modules/orderStore'
import performanceStore from './modules/performanceStore'
import noticeStore from './modules/noticeStore'
import permissionStore from './modules/permissionStore'
import CONSTANT from '@/common/constant'

interface UserPermissions {
    key: string | null,
    action: string  | null,
    name: string | null,
}

interface Role {
    id: any,
    name: string | null,
    service_id: any,
    permissions: UserPermissions[],
}

export default createStore({
    state: {
        isLoading: false,
        isShowModalExport: false,
        fileNameExport: null,
        statusClearFilter: false,
        ckeditorData: null,
        ckeditorDataAfter: null,
        isRerender: false,
        templateActiveID: 1,
        permissions: [] as UserPermissions[],
        loadedPermission: false
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        isShowModalExport(state): boolean {
            return state.isShowModalExport;
        },
        fileNameExport(state): any {
            return state.fileNameExport;
        },
        statusClearFilter(state): any {
            return state.statusClearFilter;
        },
        ckeditorData(state): any {
            return state.ckeditorData;
        },
        ckeditorDataAfter(state): any {
            return state.ckeditorDataAfter;
        },
        isRerender(state): any {
            return state.isRerender;
        },
        templateActiveID(state): any {
            return state.templateActiveID;
        },
        permissions(state): any {
            return state.permissions;
        },
        getPermissions: (state) => (key: string | null, action: string | null) => { 
            return state.permissions.find((perm) => perm.key === key && perm.action === action); 
        },
        loadedPermission(state): any {
            return state.loadedPermission;
        }
    },
    mutations: {
        setLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setShowModalExport(state, isShowModalExport) {
            state.isShowModalExport = isShowModalExport
        },
        setFileNameExport(state, fileNameExport) {
            state.fileNameExport = fileNameExport
        },
        setClearFilter(state, status) {
            state.statusClearFilter = status
        },
        setCKEditorData(state, data) {
            state.ckeditorData = data
        },
        setCKEditorDataAfter(state, data) {
            state.ckeditorDataAfter = data
        },
        setIsRerender(state, data) {
            state.isRerender = data
        },
        setTemplateActiveID(state, data) {
            state.templateActiveID = data
        },
        setPermissions(state, data) {
            state.permissions = data;
            state.loadedPermission = true;
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading);
        },
        setShowModalExport({ commit }, isShowModalExport) {
            commit('setShowModalExport', isShowModalExport);
        },
        setFileNameExport({ commit }, fileNameExport) {
            commit('setFileNameExport', fileNameExport);
        },
        setClearFilter({ commit }, status) {
            commit('setClearFilter', status);
        },
        setCKEditorData({ commit }, data) {
            commit('setCKEditorData', data);
        },
        setCKEditorDataAfter({ commit }, data) {
            commit('setCKEditorDataAfter', data);
        },
        setIsRerender({ commit }, data) {
            commit('setIsRerender', data);
        },
        setTemplateActiveID({ commit }, data) {
            commit('setTemplateActiveID', data);
        },
        setPermissions({ commit }, response) {
            if(response && response.data && response.data.roles) {
                const roles = response.data.roles as Role[];
                const permissions: UserPermissions[] = [];
                roles.forEach(role => {
                    if (role.permissions && role.permissions.length > 0 && role.service_id == CONSTANT.SERVICE_AFFILIATE_ID) {
                        role.permissions.forEach(item => {
                            let flag = false;
                            permissions.map(v => {
                                if (v?.key === item.key && v?.action === item.action) {
                                    flag = true
                                }
                            });
                            if (!flag) {
                                permissions.push(item);
                            }
                        });
                    }
                });
                commit('setPermissions', permissions);
            }
        },
    },
    modules: {
        auth,
        marketingChannels,
        productStore,
        campaignStore,
        userStore,
        partners,
        productLinks,
        payment,
        uploadFile,
        collaborators,
        exportStore,
        orderStore,
        performanceStore,
        noticeStore,
        permissionStore
    }
})
