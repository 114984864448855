<template>
    <div class='modal fade' ref="ModalEdit" id='ModalEdit' aria-labelledby='ModalEdit' aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg modal-dialog-scrollable'>
            <div class='modal-content p-0'>
                <div class='modal-header border-bottom-0'>
                    <button type='button' class='btn-close' @click.stop="hide"></button>
                </div>
                <div class='modal-body pt-0'>
                    <div class='mt-3 w-100 min-h-500'>
                        <CKEditorComponent />
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" @click="save">
                        <span>{{ this.$t('save') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import CKEditorComponent from "@/components/CkEditor/CkEditor.vue";
import { mapGetters } from 'vuex';
import toastr from 'toastr';

export default{
    name: 'ModalEdit',
    components: {
        CKEditorComponent,
    },
    data(){
        return {
            product_code: null,
        }
    },
    computed: {
        ...mapGetters(['ckeditorDataAfter', 'templateActiveID'])
    },
    methods: {
        show(product_code){
            if(product_code){
                this.product_code = product_code;
                window.jQuery(this.$refs.ModalEdit).modal('show');
            }
        },
        hide(){
            window.jQuery(this.$refs.ModalEdit).modal('hide');
        },
        async save(){
            const params = {
                product_code: this.product_code,
            };
            if(this.templateActiveID == 1){
                params.description = this.ckeditorDataAfter;
            }else{
                params.description_advertising = this.ckeditorDataAfter;
            }
            const res = await this.$store.dispatch('productStore/updateProductDescription', params);
            if(res.status){
                this.$store.dispatch('setIsRerender', true);
                toastr.success(this.$t('msgUpdateSuccess'));
                this.hide();
                return;
            }
            toastr.error(this.$t('msgUpdateError'));
        }
    }
};
</script>
<style lang="scss" scoped>
#ModalEdit{
    .min-h-500{
        min-height: 500px;
    }
}
</style>