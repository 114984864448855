<template>
    <div id="target-campaign-detail-page">
        <div class='container-fluid p-3'>
            <div class="row">
                <div class="col-lg-4">
                    <span><strong>{{ $t('campaignCode') }}:</strong> {{ this.code }}</span>
                </div>
                <div class="col-lg-4">
                    <span><strong>{{ $t('status') }}:</strong> <span :class="this.status ? getClassForTargetCampaignStatus(this.status) : ''">{{ this.status }}</span></span>
                </div>
                <div class="col-lg-4">
                    <span><strong>{{ $t('time') }}:</strong> {{ this.time }}</span>
                </div>
            </div>
            <br>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h5 class='card-title mb-2'>{{ numberFormat(products?.length) }} {{ $t('product') }}</h5>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('estCommission') }}</th>
                                        <th scope="col">{{ $t('numberOfSales') }}</th>
                                        <th scope="col">{{ $t('percentCommission') }}</th>
                                        <th scope="col">{{ $t('Click') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(entry, index) in products" :key="index">
                                        <tr>
                                            <td>
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img :src="entry.main_image" />
                                                    </div>
                                                    <div class="product mx-2">
                                                        <p>
                                                            {{ entry.name }}
                                                        </p>
                                                        <p>
                                                            {{ $t('skuIdCode') }}: {{
                                                            entry.sku }}
                                                        </p>

                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span v-if="entry.price">
                                                    {{ $filters.formatPrice((entry.price * entry.commission_rate_contributor)/100) }}</span>
                                                <span v-else>0</span>
                                            </td>
                                            <td>{{ entry.sale_count ?? 0 }}</td>
                                            <td>{{ entry.commission_rate_contributor }} %</td>
                                            <td>
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-primary" @click="showModalEditProduct(entry)" 
                                                    :disabled="this.status == $t('campaignEnd') || this.status == $t('campaignStatusClose') ? true : false">
                                                        Link
                                                    </button>
                                                    <!-- <ul class="dropdown-menu">
                                                        <template
                                                            v-for="(channel, indexChannel) in this.listMarketingChannels"
                                                            :key="indexChannel">
                                                            <li><a @click="renderAndCopyLink(
                                                                this.userInfo.id,
                                                                entry.slug,
                                                                channel.id,
                                                                this.campaignId,
                                                                entry.target_campaign_contributor_id,
                                                                entry.name,
                                                                entry.code,
                                                                'target')" class="dropdown-item" href="#">{{
                                                                    channel.name }}</a>
                                                            </li>
                                                        </template>
                                                    </ul> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <EditDescription ref="EditDescription"/>
</template>

<script>
import { numberFormat, generateAffiliateLink, getClassForTargetCampaignStatus } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import Daterangepicker from '@/components/Daterangepicker.vue';
import { mapGetters, mapActions } from 'vuex';
import toastr from 'toastr';
import store from "@/store";
import EditDescription from '@/components/Modal/Product/EditDescription.vue';

export default {
    name: 'TargetCampaignContributor',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        Daterangepicker,
        EditDescription
    },
    data() {
        return {
            isLoading: false,
            products: [],
            campaignId: null,
            code: null,
            time: null,
            status: null,
            targetCampaignPartnerId: null,
            listMarketingChannels: [],
            
        }
    },
    created() {
        this.campaignId = this.$route.params.id;
        // this.fetchCampaignDetail();
        this.getDataCampaignForContributor();
    },
    mounted() {
        this.loadData();
    },
    computed: {
        // ...mapGetters('marketingChannels', ['listMarketingChannels']),
        ...mapGetters({
            userInfo: 'auth/userInfo'
        }),
    },
    methods: {
        ...mapActions({
            getDetailCampaign: 'campaignStore/getDetailCampaign',
        }),
        async getDataCampaignForContributor() {
            const result = await store.dispatch('campaignStore/getDataCampaignForContributor', {
                campaignId: this.campaignId
            });  
            if (result && result.campaign) {                
                this.time = this.$filters.formatStartTimeAndEndTime(result.campaign.start_time, result.campaign.end_time);
                this.code = result.campaign.campaign.code;
                this.status = this.$filters.formatStatusCampaignPartner(result.campaign.status || result.campaign.campaign.status, result.campaign.start_time, result.campaign.end_time)
            }
            if (result && result.productsCampaign) {
                this.products = result.productsCampaign;
            }            
        },
        
        // async fetchCampaignDetail() {

        //     try {
        //         const result = await this.getDetailCampaign({ id: this.campaignId });
        //         console.log('result', result);
                
        //         this.products = result.target_campaign_products;
        //         this.products = this.mapProductWithFirstRate(this.products, result.target_campaign_contributors);
        //         this.code = result.code;
                
        //         const foundPartner = result?.target_campaign_partners?.find(partner => partner.id === this.targetCampaignPartnerId);     
        //         console.log(foundPartner);
                           
        //         this.time = this.$filters.formatStartTimeAndEndTime(foundPartner.start_time, foundPartner.end_time)
        //         this.status = this.$filters.formatStatusCampaignPartner(foundPartner.status || result.status, foundPartner.start_time, foundPartner.end_time)
        //     } catch (error) {
        //         console.error('Error fetching campaign data:', error);
        //     }
        // },
        // mapProductWithFirstRate(products, contributors) {
        //     return products?.map(product => {
        //         const firstContributor = contributors?.find(contributor =>
        //             contributor.target_campaign_product_id === product.id
        //         );
        //         this.targetCampaignPartnerId = firstContributor?.target_campaign_partner_id
        //         console.log('this.targetCampaignPartnerId', this.targetCampaignPartnerId);

        //         return {
        //             ...product,
        //             commission_rate_contributor: firstContributor ? firstContributor.commission_rate_contributor : null,
        //             target_campaign_contributor_id: firstContributor ? firstContributor.id : null
        //         };
        //     });
        // },
        renderAndCopyLink(contributor_id, slug, channel_id, campaign_id, target_campaign_contributor_id, name, code , type) {
            const link = generateAffiliateLink(slug, contributor_id, channel_id, campaign_id);
            navigator.clipboard.writeText(link).then(() => {
                this.save(link, campaign_id, target_campaign_contributor_id, code, channel_id, type);
                toastr.success('Đã copy link giới thiệu của <br>' + '<b>' + name + '</b>');
            }).catch(err => {
                toastr.error('Không thể sao chép liên kết');
            });
        },
        async loadData() {
            // const res = await this.$store.dispatch('marketingChannels/fetchMarketingChannels');
            // const res = await this.$store.dispatch('marketingChannels/getListMarketingChannels');
            // if(res){
            //     this.listMarketingChannels = res;
            // }
        },
        async save(link, campaign_id, target_campaign_contributor_id, code, channel_id, type) {
            const params = {
                target_campaign_contributor_id: target_campaign_contributor_id,
                url: link,
                campaign_id: campaign_id,
                marketing_channel_id: channel_id,
                product_code: code,
                type: type
            };
            await this.$store.dispatch('productLinks/createProductLinkChannel', params);
        },
        showModalEditProduct(entry){
            if(!entry){
                return;
            }
            let productLinkDetail = {
                campaign_id: this.campaignId,
                type: 'target',
                target_campaign_contributor_id: entry.target_campaign_contributor_id,
                slug: entry?.slug ?? null,
                save: true,
            };
            this.$store.dispatch('productLinks/setProductLinkDetail', productLinkDetail);
            this.$refs.EditDescription.show();
        }
    },
};
</script>

<style lang="scss" scoped>
#target-campaign-detail-page {
    .table-responsive{
        height: 100vh;
    }
    
    .info-products {
        .image{
            width: 60px;
        }
        .image img{
            width: 100%;
            transform: scale(1);
        }
    }
}
</style>