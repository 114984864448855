<template>
    <div id="create-target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between">
                            <h4 class='card-title mb-2'>{{ $t('choosePartner') }}</h4>
                            <button class="btn btn-primary" @click="showModalAddUser()" role='button'
                                v-if="partners?.length">
                                <i class="fa fa-plus mx-2"></i>
                                <span>
                                    {{ $t('createPartner') }}
                                </span>
                            </button>
                        </div>
                        <h6 class='card-title mb-2'>{{ (partners ? partners.length : 0) }} {{ $t('partner') }}</h6>
                        <div class="table-responsive" v-if="partners?.length">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('partnerName') }}</th>
                                        <th scope="col">{{ $t('numberOfCollaborators') }}</th>
                                        <th scope="col">{{ $t('numberOfClicked') }}</th>
                                        <th scope="col">{{ $t('numberOfOrders') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(partner, index) in partners" :key="index">
                                        <tr>
                                            <td>
                                                <p>{{ partner.name }}</p>
                                            </td>
                                            <td>{{ partner.affiliate_partners_count ?? 0 }}</td>
                                            <td>{{ partner.quantity_click ?? 0 }}</td>
                                            <td>{{ partner.quantity_order ?? 0 }}</td>
                                            <td>
                                                <!-- <span :class="(partnersOrigin.find((origin) => origin.id == partner.id)) ? 'disabled' : ''">
                                                    <i @click="showModalConfirmDeletePartner(index, partner.id, partner.name)" class="fa fa-trash-alt"></i>
                                                </span> -->
                                                <span
                                                    :class="(partnersOrigin.find((origin) => origin.id == partner.id) && this.isPartnerDisabled()) ? 'disabled' : ''">
                                                    <i @click="showModalConfirmDeletePartner(index, partner.id, partner.name)"
                                                        class="fa fa-trash-alt"></i>
                                                </span>



                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center flex-column border"
                            v-else>
                            <button @click="showModalAddUser()" class="btn btn-outline-secondary">
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('createPartner') }}</span>
                            </button>
                            <ul class="error-label" v-if="errors && errors['partner_ids']?.length">
                                <li v-for="error in errors['partner_ids']" :key="error" v-text="error"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between">
                            <h4 class='card-title mb-2'>{{ $t('setupCommission') }}</h4>
                            <div>
                                <button class="btn btn-primary mx-2" @click="showModalChoseProduct()"
                                    v-if="products?.length">
                                    <i class="fa fa-plus mx-2"></i>
                                    <span>
                                        {{ $t('createProduct') }}
                                    </span>
                                </button>
                                <button class="btn btn-secondary" role='button' v-if="products?.length"
                                    :disabled="productCodes?.length ? false : true"
                                    @click="productCodes?.length ? showSetRate() : null">
                                    <i class="fa fa-plus mx-2"></i>
                                    <span>
                                        {{ $t('createQuicklyCommission') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <h6 class='card-title mb-2'>{{ (products ? products.length : 0) }} {{ $t('product') }}</h6>
                        <div class="table-responsive table-products" v-if="products?.length">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll"
                                                type="checkbox" />
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('percentCommission') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(product, index) in products" :key="index">
                                        <tr>
                                            <th>
                                                <input v-model="productCodes" :value="product.code" type="checkbox" />
                                            </th>
                                            <td>
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img :src="product.main_image ?? image_default" />
                                                    </div>
                                                    <div class="product mx-1">
                                                        <p>{{ product.name }}</p>
                                                        <p>
                                                            {{ $t('skuIdCode') }}: {{
                                                            product?.product_variant_max_price?.sku }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span v-if="product.product_variant_max_price">{{
                                                    $filters.formatPrice(product.product_variant_max_price.price)
                                                    }}</span>
                                                <span v-else>0</span>
                                            </td>
                                            <td>
                                                <InputTextAppend v-model="product.commission_rate" :max="100" :min="1"
                                                    :errors="this.errors['products.' + index + '.commission_rate'] ?? []" />
                                            </td>
                                            <td>
                                                <span
                                                    :class="(productsOrigin.find((origin) => origin.id == product.id) && this.isPartnerDisabled()) ? 'disabled' : ''">
                                                    <i @click="showModalConfirmDeletePrd(index, product.id, product.name)"
                                                        class="fa fa-trash-alt"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center flex-column border"
                            v-else>
                            <button class="btn btn-outline-secondary" @click="showModalChoseProduct()">
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('createProduct') }}</span>
                            </button>
                            <ul class="error-label" v-if="errors && errors['products']?.length">
                                <li v-for="error in errors['products']" :key="error" v-text="error"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h4 class='card-title mb-2'>{{ $t('sendInvitations') }}</h4>
                        <div class="form-send-infor mx-0 p-3">
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignName') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <InputText v-model="name" :disabled="setDisabled()"
                                        :errors="this.errors?.name ?? []" :placeholder="$t('enterCampaignName')" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignTime') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <div class='row'>
                                        <div class='col-lg-6 col-md-12 col-sm-12'>
                                            <div class="d-flex">
                                                <SingleDaterangepicker :placeholder="$t('startTime')"
                                                    :class="!setEndTime ? 'w-100' : ''" v-model="start_time"
                                                    :errors="this.errors?.start_time ?? []" :disabled="setDisabled()" />
                                                <SingleDaterangepicker :placeholder="$t('endTime')" v-if="setEndTime"
                                                    class="mx-2" v-model="end_time"
                                                    :errors="this.errors?.end_time ?? []" :disabled="setDisabled()" />
                                            </div>
                                        </div>
                                        <div class='col-lg-6 col-md-12 col-sm-12 d-flex align-items-end'>
                                            <InputCheckbox :disabled="setDisabled()" :label="$t('setEndTime')"
                                                @update:modelValue="setEndTime" v-model="setEndTime" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('message') }}:
                                </label>
                                <div class="col-sm-10">
                                    <InputTextArea :disabled="setDisabled()" :placeholder="$t('descriptionOfCampaign')"
                                        :errors="this.errors?.message ?? []" v-model="message" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12 d-flex justify-content-end'>
                    <button class="btn btn-outline-secondary mx-2" @click="cancel()">
                        <span>
                            {{$t('cancel')}}
                        </span>
                    </button>
                    <AppPermission :keyPerm="$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                        <button class="btn btn-outline-warning mx-2" @click="submitData($constant.CAMPAIGN.statusDraft)"
                            v-if="status === $constant.CAMPAIGN.statusDraft">
                            <span>
                                {{$t('saveDraft')}}
                            </span>
                        </button>
                        <button class="btn btn-warning" @click="submitData($constant.CAMPAIGN.statusActive)">
                            <span>
                                {{$t('send')}}
                            </span>
                        </button>
                    </AppPermission>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <ModalChoosePartner ref="ModalChoosePartner" />
    <ModalChooseProduct ref="ModalChooseProduct" />
    <ModalCreateQuicklyCommission ref="ModalCreateQuicklyCommission" />
    <ModalConfirmDeletePartner :title="$t('removePartner') + partnerNameRemove" :content="$t('contentRemovePartner')"
        :button="$t('remove')" @confirm="removePartner" ref="ModalConfirmDeletePartner" />
    <ModalConfirmDeleteProduct :title="$t('removeProduct') + prdNameRemove" :content="''" :button="$t('remove')"
        @confirm="removePrd" ref="ModalConfirmDeleteProduct" />
    <ModalConfirmDeletePartner :title="$t('cancelEditCampaignTitle')" :content="$t('cancelEditCampaign')"
        :button="$t('confirm')" @confirm="confirmCancel" ref="ModalConfirmCancel" />
</template>

<script>
import { numberFormat, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue'
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import ModalChoosePartner from '@/components/Modal/TargetCampaign/ModalChoosePartner.vue';
import ModalChooseProduct from '@/components/Modal/TargetCampaign/ModalChooseProduct.vue';
import ModalCreateQuicklyCommission from '@/components/Modal/TargetCampaign/ModalCreateQuicklyCommission.vue';
import ModalConfirmDeletePartner from '@/components/Modal/ModalConfirm.vue';
import ModalConfirmDeleteProduct from '@/components/Modal/ModalConfirm.vue';
import toastr from 'toastr';
import store from "@/store";
import { mapGetters } from 'vuex';
import image_default from '../../../public/images/default-image.jpg';
import moment from 'moment';
import InputTextArea from '@/components/Form/InputTextArea.vue'
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: 'CreateTargetCampaign',
    setup() {
        return { numberFormat, checkPermission };
    },
    components: {
        Loading,
        InputText,
        InputSelect,
        SingleDaterangepicker,
        InputCheckbox,
        InputTextAppend,
        ModalChoosePartner,
        ModalChooseProduct,
        ModalCreateQuicklyCommission,
        ModalConfirmDeletePartner,
        ModalConfirmDeleteProduct,
        InputTextArea,
        AppPermission
    },
    data() {
        return {
            currentId: null,
            partners: [],
            partnersOrigin: [],
            products: [],
            productsOrigin: [],
            statusCheckAll: false,
            productCodes: [],

            start_time: null,
            end_time: null,
            name: null,
            message: null,
            setEndTime: false,

            partnerIndexRemove: null,
            partnerNameRemove: null,
            statusCheckAll: false,
            prdIndexRemove: null,
            prdNameRemove: null,
            isLoading: false,
            image_default: image_default,

            // data submit
            start_time: null,
            end_time: null,
            name: null,
            message: null,
            status: null,
            errors: [],
            code: null,
            start_time_origin: null,
            partner_or_contributor_remove: [],
            partnerIdToRemove: [],
            product_remove: [],
            productIdToRemove: []
        }
    },
    computed: {
        ...mapGetters({
            partnersNew: 'userStore/partnersNew',
            productsAdd: 'productStore/products_add_campaign_target',
            commissionRate: 'campaignStore/commission_rate',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        }),
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN, this.$constant.PERMISSIONS.PER_ACTION_UPDATE)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    watch: {
        partnersNew: {
            handler: function(newData) {                
                for (let index = 0; index < newData.length; index++) {
                    const partnerNew = newData[index];
                    let itemIndex = this.partners.findIndex((partner) => partner.id == partnerNew.id);
                    let checkPartnersOrigin = this.partnersOrigin.find((partner) => partner.id == partnerNew.id);
                    if (checkPartnersOrigin) {
                        let indexRemove = this.partner_or_contributor_remove.findIndex((id) => checkPartnersOrigin.id == id);
                        
                        if (indexRemove != -1) {
                            this.partner_or_contributor_remove.splice(indexRemove, 1);
                        }
                    }
                    if (itemIndex === -1) {
                        this.partners.push(partnerNew);
                    }
                }
            },
            deep: true
        },
        productsAdd: {
            handler: function(newDataPrd) {                                
                for (let index = 0; index < newDataPrd.length; index++) {
                    const prdAdd = newDataPrd[index];
                    let itemIndex = this.products.findIndex((prd) => prd.id == prdAdd.id);

                    let checkProductOrigin = this.productsOrigin.find((prd) => prd.id == prdAdd.id);
                    if (checkProductOrigin) {
                        let indexRemove = this.product_remove.findIndex((id) => checkProductOrigin.id == id);
                        if (indexRemove != -1) {
                            this.product_remove.splice(indexRemove, 1);
                        }
                    }
                    if (itemIndex === -1) {
                        this.products.push(prdAdd);
                        this.statusCheckAll = false;
                    }
                }
            },
            deep: true
        },
        setEndTime: function (newStatus) {
            if (!newStatus) {
                this.end_time = null;
            }
        },
        commissionRate: function (newStatus) {
            let products = [...this.products];
            for (let index = 0; index < this.products.length; index++) {
                const product = this.products[index];
                if (this.productCodes.includes(product.code)) {
                    product['commission_rate'] = newStatus;
                }
            }
            this.onCheckAll(false);
            this.products = products;
        },
        productCodes: {
            handler: function(newPrdCode) {                                                
                if (newPrdCode.length == this.products.length) {
                    this.statusCheckAll = true;
                } else {
                    this.statusCheckAll = false;
                }
            },
            deep: true
        },
    },
    created() {
        this.getIdDetail();
        this.getDetailCampaign();
    },
    methods: {
        onCheckAll(status) {
            this.statusCheckAll = status;
            if (this.statusCheckAll) {
                let productCodes = [];
                for (let index = 0; index < this.products.length; index++) {
                    const product = this.products[index];
                    productCodes.push(product.code);
                }
                this.productCodes = productCodes;
            } else {
                this.productCodes = [];
            }
        },
        isPartnerDisabled() {
            return moment(this.start_time).isSameOrBefore(moment())
        },
        showSetRate() {
            this.$refs.ModalCreateQuicklyCommission.show();
        },
        getIdDetail() {
            this.currentId = this.$route.query.id ?? null;
        },
        async getDetailCampaign() {
            const res = await store.dispatch('campaignStore/getDetailCampaign', {id: this.currentId});
            if (!res || !res.code) {
                return;
            }
            this.partnersOrigin = this.formatPartner(res.target_campaign_partners);
            this.partners = this.formatPartner(res.target_campaign_partners);
            this.productsOrigin = this.formatProducts(res.target_campaign_products);
            this.products = this.formatProducts(res.target_campaign_products);
            this.name = res.name;
            this.start_time = res.start_time;
            this.end_time = res.end_time;
            this.message = res.messages;
            this.status = res.status;
            this.code = res.code;
            this.start_time_origin = res.start_time;
            if (res.end_time) {
                this.setEndTime = true;
            }
        },

        formatProducts(targetCampaignProducts) {
            let products = [];
            for (let index = 0; index < targetCampaignProducts.length; index++) {
                const item = targetCampaignProducts[index];
                let product = item.product;
                products.push({
                    code: product.code ?? null,
                    id:  product.id ?? null,
                    main_image: product.main_image ?? null,
                    name: product.name ?? null,
                    status: product.status ?? null,
                    product_variant_max_price: product.product_variant_max_price,
                    commission_rate: item.commission_rate_partner,
                });
            }
            return products;
        },

        formatPartner(targetCampaignPartners) {
            let partners = [];
            for (let index = 0; index < targetCampaignPartners.length; index++) {
                const partner = targetCampaignPartners[index];
                partners.push({
                    affiliate_partners_count: partner.affiliate_partners_count ?? 0,
                    id: partner.partner_id,
                    partner_id: null,
                    name: partner.name,
                    quantity_click: partner.quantity_click ?? 0,
                    quantity_order: partner.quantity_order ?? 0,
                    type: partner.type ?? null,
                });
            }
            return partners;
        },

        showModalAddUser() {
            this.$refs.ModalChoosePartner.show([...this.partners], [...this.partnersOrigin]);
            
        },
        showModalConfirmDeletePartner(index, partnerId, partnerNameRemove) {
            let partnerOrigin = this.partnersOrigin.find((origin) => origin.id == partnerId) && moment(this.start_time).isSameOrBefore(moment());
            if (partnerOrigin) {
                return;
            }
            this.partnerIdToRemove = partnerId;
            this.partnerIndexRemove = index;
            this.partnerNameRemove = partnerNameRemove,
            this.$refs.ModalConfirmDeletePartner.show();
        },
        removePartner() {
            this.partners.splice(this.partnerIndexRemove, 1);
            this.partner_or_contributor_remove.push(this.partnerIdToRemove);
        },
        showModalChoseProduct() {
            this.$refs.ModalChooseProduct.show([...this.products], [...this.productsOrigin]);
        },
        showModalConfirmDeletePrd(index, productId, prdNameRemove) {
            let indexPrd = this.productsOrigin.findIndex((origin) => origin.id == productId);
            if (indexPrd !== -1 && moment(this.start_time).isSameOrBefore(moment())) {
                return;
            }
            this.productIdToRemove = productId;
            this.prdIndexRemove = index;
            this.prdNameRemove = prdNameRemove,
            this.$refs.ModalConfirmDeleteProduct.show();
        },
        removePrd() {
            this.products.splice(this.prdIndexRemove, 1);
            this.product_remove.push(this.productIdToRemove);
            store.dispatch('productStore/addProductCampaignTarget', this.products);
        },
        async submitData(status) {
            let partner_ids = this.formatPartnersSubmit();            
            let products = this.formatProductsSubmit();
            const res = await store.dispatch('campaignStore/updateCampaignTarget', {
                id: this.currentId,
                partner_ids: partner_ids,
                products: products,
                start_time: this.start_time,
                end_time: this.end_time,
                name: this.name,
                message: this.message,
                status: status,
                code: this.code,
                partner_or_contributor_remove: this.partner_or_contributor_remove,
                product_remove: this.product_remove
            });
            if (!res || res.status === 500) {
                toastr.error(this.$t('msgUpdateError'));
            } else if (res.status === 422) {
                // let errors = res.response;
                // Object.keys(errors).forEach(function(key) {
                //     if(key == 'partner_ids' || key == 'products'){
                //         toastr.error(errors[key][0]);
                //     }
                // })
                this.errors = res.response ?? [];
            } else if (res.status === 200) {
                toastr.success(this.$t('msgUpdateSuccess'));
                this.$router.push({name: this.$constant.ROUTER.targetCampaignName});
            }
        },
        cancel() {
            this.$refs.ModalConfirmCancel.show();
        },
        confirmCancel() {
            this.$router.push({name: this.$constant.ROUTER.targetCampaignName});
        },
        formatProductsSubmit() {
            let prds = [];
            for (let index = 0; index < this.products.length; index++) {
                const prd = this.products[index];
                prds.push({
                    code: prd.code,
                    commission_rate: prd.commission_rate,
                });
            }
            return prds;
        },
        formatPartnersSubmit() {

            let ids = [];                        
            for (let index = 0; index < this.partners.length; index++) {
                const partner = this.partners[index];
                if (partner.type == this.$constant.PARTNER.type_partner) {
                    ids.push({id: partner.id, isPartner: true});
                } else {
                    ids.push({id: partner.id, isPartner: false});
                }
            }
            return ids;
        },

        setDisabled(){
            const start_time = moment(this.start_time_origin, 'YYYY-MM-DD HH:mm:ss');
            const now = moment();
            if(this.status == this.$constant.CAMPAIGN.statusDraft || (this.status == this.$constant.CAMPAIGN.statusActive && start_time.isAfter(now))){
                return false;
            }
            return true;
        }




        // showModalConfirmDeletePrd(index, prdNameRemove) {
        //     this.prdIndexRemove = index;
        //     this.prdNameRemove = prdNameRemove,
        //     this.$refs.ModalConfirmDeleteProduct.show();
        // },
        // removePrd() {
        //     this.products.splice(this.prdIndexRemove, 1);
        //     store.dispatch('productStore/addProductCampaignTarget', this.products);
        // },
        // showModalChosePrd() {            
        //     this.$refs.ModalChooseProduct.show(this.products);
        // },

        // showModalConfirmDeletePartner(index, partnerNameRemove) {
        //     this.partnerIndexRemove = index;
        //     this.partnerNameRemove = partnerNameRemove,
        //     this.$refs.ModalConfirmDeletePartner.show();
        // },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>