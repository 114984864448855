<template>
    <div id="administrator-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='row'>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <InputText :placeholder="$t('search')" v-model.trim="filters.keyword"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <Daterangepicker :placeholder="$t('createdAt')" v-model="filters.created"/>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <button type='submit' class='btn btn-success' @click="search">
                            <span>
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary mx-2' @click="clearFilter">
                            <span>
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
                <div class='row mt-2'>
                    <div class="col-xl-12 col-md-12 col-sm-12">
                        <AppPermission :keyPerm="$constant.PERMISSIONS.PER_ADMINISTRATOR" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                            <button type='submit' class='btn btn-primary' @click="create">
                                <span>
                                    {{ $t('createAdministrator') }}
                                </span>
                            </button>
                        </AppPermission>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="card-title mb-2" v-text="$t('listPartnerContributors')"></div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col" class="cell-id">{{ $t('ID') }}</th>
                                        <th scope="col">{{ $t('administratorname') }}</th>
                                        <th scope="col">{{ $t('email') }}</th>
                                        <th scope="col">{{ $t('roles') }}</th>
                                        <th scope="col">{{ $t('lastLogin') }}</th>
                                        <th scope="col" class="cell-action">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-text="entry.id"></td>
                                            <td v-text="entry.name"></td>
                                            <td v-text="entry.email"></td>
                                            <td>
                                                <template v-if="entry?.roles && entry?.roles?.length">
                                                    <template  v-for="role in entry.roles" :key="role">
                                                        <span v-if="role.service_id == this.$constant.SERVICE_AFFILIATE_ID" class="label-success mx-1">
                                                            {{ role.name }}
                                                        </span>
                                                    </template>
                                                </template>
                                            </td>
                                            <td v-text="entry.last_login ? formatDateCTV(entry.last_login, 'YYYY-MM-DD H:s') : null"></td>
                                            <td>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_ADMINISTRATOR" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                                    <span class="cursor-pointer" @click="updateUserPermission(entry.id)">
                                                        <span class="icon_tooltip" :titleCustom="$t('process')"><i class="far fa-edit text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" @pageChange="pageChange" v-if="this.entries?.length > 0"/>
        </div>
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, formatDateCTV, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import { mapGetters } from 'vuex';
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: 'Administrator',
    setup() {
        return { numberFormat, formatDateCTV, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        AppPermission
    },
    data() {
        return {
            isLoading: false,
            record: 100,
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            filters: {
                service_id: this.$constant.SERVICE_AFFILIATE_ID,
                roles: 30
            },
        }
    },
    mounted(){
        this.loadDataUsers();
    },
    computed: {
        ...mapGetters({
            entries: 'permissionStore/getListUsers',
            user: 'auth/userInfo',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_ADMINISTRATOR, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    methods: {
        async loadDataUsers() {
            const res = await this.$store.dispatch('permissionStore/getListUsers', this.filters);
            this.paginate.totalRecord = res?.paginate?.currentPage * res?.paginate?.lastPage;
            this.paginate.currentPage = res?.paginate?.currentPage;
            this.paginate.lastPage = res?.paginate?.lastPage;
        },
        search(){
            this.filters.page = 1;
            this.loadDataUsers();
        },
        clearFilter(){
            this.filters = {
                service_id: this.$constant.SERVICE_AFFILIATE_ID,
                roles: 30,
                keyword: null,
                created: null,
            },
            this.loadDataUsers();
        },
        updateUserPermission(userId){
            this.$router.push({name: this.$constant.ROUTER.updatePermissionName, params: {
                id: userId
            }});
        },
        create(){
            this.$router.push({name: this.$constant.ROUTER.createAdministratorName});
        },
        pageChange(page) {
            this.filters.page = page
            this.loadDataUsers()
        },
    },
};
</script>

<style lang="scss" scoped>
</style>