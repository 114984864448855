<template>
    <router-view/>
    <Loading/>
    <ModalExport ref="modalExport"/>
</template>
<script>
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
import Loading from './components/Loading.vue';
import ModalExport from './components/Modal/ModalExport.vue';
import { mapGetters } from 'vuex';
import pusher from '@/pusher';
import { v4 as uuidv4 } from 'uuid';
export default{
    name: 'App',
    components: {
        Loading,
        ModalExport
    },
    data(){
        return {
            channelName: null,
            channelNoticeName: null,
            fileName: null,
            deviceId: null,
        }
    },
    async mounted() {
        await this.$store.dispatch('auth/fetchUserInfo');
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "preventDuplicates": false,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        this.getDeviceId();
        this.listenEventPusher();
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo'
        })
    },
    methods: {
        listenEventPusher(){
            if(this.channelName){
                var channel = pusher.subscribe(this.channelName);
                channel.bind('export-file-event', (data) => {            
                    this.$store.dispatch('setFileNameExport', data?.data?.fileName)
                });
            }
            if(this.channelNoticeName){
                var channelNotice = pusher.subscribe(this.channelNoticeName);
                channelNotice.bind('notice-event', (data) => {            
                    this.$store.dispatch('noticeStore/setNumberNotice', data?.data?.number)
                });
            }
        },
        getDeviceId() {
            let deviceId = localStorage.getItem('deviceIdAffiliate');
            if (!deviceId) {
                deviceId = uuidv4();
                localStorage.setItem('deviceIdAffiliate', deviceId);
            }
            this.deviceId = deviceId;   
        }
    },
    watch: {
        user: {
            handler: function(newData) {                
                if(newData){
                    this.channelName = "export-file-" + newData.id + this.deviceId + "-channel";
                    this.channelNoticeName = "notice-" + newData.id + "-channel";
                    this.listenEventPusher();
                }
            },
            deep: true
        },
        deviceId: {
            handler: function(newData) {                
                if(newData){
                    this.channelName = "export-file-" + this?.user.id + newData + "-channel";
                    this.listenEventPusher();
                }
            },
            deep: true
        },

    },
};
</script>
<style>
@import "./assets/scss/app.scss";
</style>
