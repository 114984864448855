<template>
    <div id="open-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row' v-if="filters.tab === 2">
                <div class='col-xl-12 col-md-12 col-sm-12 d-flex justify-content-end'>
                    <AppPermission :keyPerm="$constant.PERMISSIONS.PER_OPEN_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                        <InputNumber v-model="commission_rate_quick" :placeholder="$t('enterCommission')" :max="100" :min="1" class="mx-2"/>
                        <button @click="product_codes_multiple?.length > 1 ? addQuickProduct() : null" :disabled="product_codes_multiple?.length > 1 ? false : true"  type='submit' class='btn btn-secondary'>
                            <span class="mx-2">
                                {{ $t('createQuickly') }}
                            </span>
                        </button>
                    </AppPermission>
                </div>
            </div>
            <div class='row mb-3'>
                <div class='col-lg-12'>
                    <template v-for="(tab, index) in tabLists" :key="index">
                        <button class="btn" :class="tab.id === filters.tab ? 'border-bottom__custom btn-active' : ''" @click="changeTab(tab.id)">
                            <span v-if="tab.id == 1" class="card-title">
                                {{ $t(tab.label) }} ({{ numberFormat(totalProductActive) }})
                            </span>
                            <span v-else class="card-title">
                                {{ $t(tab.label) }} ({{ numberFormat(totalProductNotActive) }})
                            </span>
                        </button>
                    </template>
                </div>
            </div>
            <div class='row mb-3'>
                <div class='form-group row'>
                    <div class="col-xl-8 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterSkuId')" v-model="skuIdLocal"/>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterProductName')" v-model="productNameLocal"/>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectCategory')" v-model="categoryIdLocal" :options="categories"/>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectBrand')" v-model="brandIdLocal" :options="brands"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-12">
                        <button @click="onSearchData()" type='submit' class='btn btn-primary mx-1'>
                            <span class="mx-1">
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button @click="clearSearch()" type='submit' class='btn btn-secondary mx-1'>
                            <span class="mx-1">
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                        <AppPermission :keyPerm="$constant.PERMISSIONS.PER_OPEN_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                            <button v-if="filters.tab === 1 && product_codes_multiple && product_codes_multiple.length > 1" @click="updateRateQuick()" type='submit' class='btn btn-secondary mx-1'>
                                <span class="mx-1">
                                    {{ $t('updateRateQuick') }}
                                </span>
                            </button>
                        </AppPermission>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="row">
                            <div class="col-xl-1 col-md-1 col-sm-3">
                                <h5 class='card-title mb-2'>{{ $t('sum') }} {{ numberFormat(product_codes_multiple?.length) }}</h5>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-6 table-responsive d-flex">
                                <template v-if="filters.tab === 1">
                                    <div>
                                        <input v-model="typeAcc" :value="$constant.PAGE.typeSearchPartner" type="checkbox"/>
                                        <label class="mx-2">{{ $t('partner') }}</label>
                                    </div>
                                    <div class="mx-4">
                                        <input v-model="typeAcc" :value="$constant.PAGE.typeSearchCollaborator" type="checkbox"/>
                                        <label class="mx-2">{{ $t('collaborator') }}</label>
                                    </div>
                                </template>
                            </div>
                            <div class="col-xl-8 col-md-5 col-sm-3 table-responsive d-flex justify-content-end mb-2">
                                <button @click="exportData()" type='submit' class='btn btn-outline-secondary' v-if="filters.tab == 1">
                                    <span class="mx-2">
                                        {{ $t('exportData') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive" v-if="filters.tab === 1">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input :disabled="disabledAll" @click=onCheckAllProduct(!checkAllTypeActive) :checked="checkAllTypeActive && productsActive && productsActive?.length" type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('percentCommission') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('promotionPeriod') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="productActive in productsActive" :key="productActive.product_code">
                                        <tr>
                                            <th>
                                                <input type="checkbox" v-model="product_codes_multiple" :value="productActive.product_code"/>
                                            </th>
                                            <td>
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img v-if="productActive.product" :src="productActive.product.main_image"/>
                                                        <img v-else :src="image_default"/>
                                                    </div>
                                                    <div class="product mx-1">
                                                        <a class="sidebar-link has-arrow waves-effect waves-dark" target="_blank" :href="urlPharmaProduct(productActive.product.slug)"
                                                            aria-expanded="false">
                                                            {{ productActive.product.name }}
                                                        </a>
                                                        <p>
                                                            {{ $t('skuId') }}: {{ productActive?.product?.product_variant_max_price?.sku }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span v-if="productActive">
                                                    <span v-if="(typeAcc.includes($constant.PAGE.typeSearchPartner) && typeAcc.includes(this.$constant.PAGE.typeSearchCollaborator)) || !typeAcc.length">
                                                        {{ $filters.compareFormatRangerRate(productActive.commission_rate_partner, productActive.commission_rate_contributor) }}%
                                                    </span>
                                                    <span v-else-if="typeAcc.includes($constant.PAGE.typeSearchPartner)">
                                                        {{ numberFormat(productActive.commission_rate_partner) }}%
                                                    </span>
                                                    <span v-else>
                                                        {{ numberFormat(productActive.commission_rate_contributor) }}%
                                                    </span>
                                                    
                                                    <i role="button" v-if="!productActive.end_time || 
                                                            now < productActive.end_time" 
                                                        class="far fa-edit"
                                                        @click="showModalSetCommission(
                                                            productActive.product_code, 
                                                            productActive.commission_rate_partner, 
                                                            productActive.commission_rate_contributor)"
                                                        >
                                                    </i>
                                                </span>
                                            </td>
                                            <td>
                                                <div v-if="productActive">
                                                    {{ $filters.formatStatusOpenProduct(productActive.start_time, productActive.end_time, productActive.is_about_to_be_removed) }}
                                                </div>
                                            </td>
                                            <td>
                                                <span v-if="productActive">
                                                    {{ $filters.formatStartTimeAndEndTime(productActive.start_time, productActive.end_time) }}
                                                </span>
                                            </td>
                                            <td class="color_link nowrap">
                                                <span class="mx-2" role="button" @click="showPerformanceDetail(productActive.product_code, productActive.product.name)">
                                                    <span class="icon_action" :titleCustom="$t('seePerformance')"><img :src="icon_view_performance"/></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_OPEN_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_DELETE">
                                                    <span v-if="(productActive.end_time > nowPlus7day || !productActive.end_time || productActive.start_time > now) && $filters.formatStatusOpenProduct(productActive.start_time, productActive.end_time, productActive.is_about_to_be_removed) != $t('stopping')" class="mx-2" role='button' 
                                                        :class="[(productActive.is_about_to_be_removed ? 'label-secondary' : '')]"
                                                        @click="showModalRemoveProduct(productActive.product_code, productActive.start_time, productActive.end_time)">
                                                        <span class="icon_remove" :titleCustom="$t('remove')"><img :src="icon_remove"/></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="!productsActive || !productsActive.length">
                                        <tr>
                                            <td colspan="6" class="text-center">
                                                <span>{{ $t('noData') }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="table-responsive" v-if="filters.tab === 2">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input @click=onCheckAllProduct(!checkAllTypeNotActive) :checked="checkAllTypeNotActive && productsNotActive && productsNotActive?.length" type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('numberOfItemsSold') }}</th>
                                        <th scope="col">{{ $t('inventory') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col" class="text-center">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <tr v-for="productNotActive in productsNotActive" :key="productNotActive.id">
                                        <th>
                                            <input type="checkbox" v-model="product_codes_multiple" :value="productNotActive.code"/>
                                        </th>
                                        <td>
                                            <div class="info-products d-flex">
                                                <div class="image">
                                                    <img v-if="productNotActive.main_image" :src="productNotActive.main_image"/>
                                                    <img v-else :src="image_default"/>
                                                </div>
                                                <div class="product mx-1">
                                                    <a class="sidebar-link has-arrow waves-effect waves-dark" target="_blank" :href="urlPharmaProduct(productNotActive.slug)"
                                                        aria-expanded="false">
                                                        {{ productNotActive.name }}
                                                    </a>
                                                    <p>
                                                        <span v-if="productNotActive.product_variant_max_price">
                                                            {{ $t('skuId') }}: {{ productNotActive.product_variant_max_price.sku }}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span v-if="productNotActive && productNotActive.product_variant_max_price">
                                                {{ numberFormat(productNotActive.product_variant_max_price.price) }}
                                            </span>
                                            <span v-else>
                                                0
                                            </span>
                                        </td>
                                        <td>
                                            <!-- <span v-if="productNotActive && productNotActive.product_variant_max_price">
                                                {{ numberFormat(productNotActive.product_variant_max_price.quantity_sale) }}
                                            </span>
                                            <span v-else>
                                                0
                                            </span> -->
                                            <span>{{ productNotActive.sale_count ?? 0}}</span>
                                        </td>
                                        <td>
                                            <span>{{ numberFormat(productNotActive.inventory ?? 0)  }}</span>
                                        </td>
                                        <td>{{ $filters.formatStatusProduct(productNotActive.status) }}</td>
                                        <td class="text-center">
                                            <AppPermission :keyPerm="$constant.PERMISSIONS.PER_OPEN_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                                                <span class="color_link" role='button' @click="showModalCreateOpenCampaign(productNotActive.code)">
                                                    <span class="icon_remove" :titleCustom="$t('addInCampaign')"><img :src="icon_add"/></span>
                                                </span>
                                            </AppPermission>
                                        </td>
                                    </tr>
                                    <template v-if="!productsNotActive || !productsNotActive.length">
                                        <tr>
                                            <td colspan="7" class="text-center">
                                                <span>{{ $t('noData') }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record"/>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <ModalConfirm 
        :title="$t('confirmRemoveProduct')" 
        :content=contentRemove
        :button="$t('remove')"
        @confirm="removeProduct"
        ref="ModalConfirm"
    />
    <ModalCreate ref="ModalCreateOpenCampaign"/>
    <ModalEditCommission ref="ModalEditCommission"/>
</template>

<script>
import { numberFormat, selectedElement, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalCreate from '@/components/Modal/OpenCampaign/ModalCreate.vue';
import store from "@/store";
import { mapGetters } from 'vuex';
import image_default from '../../../public/images/default-image.jpg';
import toastr from 'toastr';
import ModalEditCommission from '@/components/Modal/OpenCampaign/ModalEditCommission.vue';
import i18n from '@/locales/i18nHelper';
import icon_remove from '../../../public/images/icon_remove.png'
import icon_view_performance from '../../../public/images/icon_view_performance.png'
import icon_add from '../../../public/images/icon_add.png'
import InputNumber from '@/components/Form/InputNumber.vue';
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: 'OpenCampaign',
    setup() {
        return { numberFormat, selectedElement, checkPermission };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        ModalConfirm,
        ModalCreate,
        ModalEditCommission,
        InputNumber,
        AppPermission
    },
    data() {
        return {
            image_default: image_default,
            productsNotActive: [],
            productsActive: [],
            totalProductNotActive: 0,
            totalProductActive: 0,
            skuIdLocal: null,
            productNameLocal: null,
            categoryIdLocal: null,
            brandIdLocal: null,
            typeAcc: [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator],
            searchData: {
                skuId: null,
                productName: null,
                categoryId: null,
                brandId: null,
            },
            record: 25,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: null,
            },
            filters: {
                tab: 1,
            },
            tabLists: [
                {id: 1, label: 'productsAdded'},
                {id: 2, label: 'productsNotAdded'},
            ],
            product_codes_multiple: [],
            commission_rate_quick: '',
            checkAllTypeNotActive: false,
            checkAllTypeActive: false,
            categories: [],
            brands: [],

            isLoading: false,
            prdCode: null,
            messages: [],
            now: moment().format('YYYY-MM-DD HH:mm:ss'),
            nowPlus7day: moment().add(7, 'days').format("DD-MM-YYYY"),
            accounts: this.$constant.PAGE.typeAcc,
            disabledAll: false,
            contentRemove: '',
            icon_remove: icon_remove,
            icon_view_performance: icon_view_performance,
            icon_add: icon_add,
        }
    },

    computed: {
        ...mapGetters({
            load_data_index: 'campaignStore/load_data_index',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        }),
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_OPEN_CAMPAIGN_ADMIN, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    created() {
        this.getListProductNotActiveOpenCampaign();
        this.getListProductActiveOpenCampaign();
        this.getListCategory();
        this.getListBrands();
    },
    methods: {
        showPerformanceDetail(productCode, productName) {  
            const originalEncodedProductCode = productCode;
            const endCodeProductName = encodeURIComponent(productName);
            const targetPath = `${this.$constant.ROUTER.performanceDetailProductOpen}?product_code=${originalEncodedProductCode}&product_name=${endCodeProductName}`;
            window.location.href = targetPath;
        },
        updateRateQuick() {
            this.$refs.ModalEditCommission.show([...this.product_codes_multiple], null, null);
        },
        showModalSetCommission(productCode, ratePartner, rateContributor) {            
            this.$refs.ModalEditCommission.show([productCode], ratePartner, rateContributor);
        },
        onCheckAllProduct(status) {
            if (this.filters.tab == 2) {
                this.checkAllTypeNotActive = status;
                this.productsNotActive.forEach(element => {
                    this.product_codes_multiple = this.selectedElement(this.product_codes_multiple, element.code, status);
                });
                // if (status) {
                //     let productsCheck = [];
                //     for (let index = 0; index < this.productsNotActive.length; index++) {
                //         const product = this.productsNotActive[index];
                //         productsCheck.push(product.code);
                //     }
                //     this.product_codes_multiple = productsCheck;
                // } else {                
                //     this.product_codes_multiple = [];
                // }
            } else {                
                this.checkAllTypeActive = status;                
                this.productsActive.forEach(element => {
                    this.product_codes_multiple = this.selectedElement(this.product_codes_multiple, element.product_code, status);
                });
                // if (status) {
                //     let productsCheck = [];
                //     for (let index = 0; index < this.productsActive.length; index++) {
                //         const product = this.productsActive[index];
                //         productsCheck.push(product.product_code);
                //     }
                //     if (!this.disabledAll) {
                //         this.product_codes_multiple = productsCheck;
                //     }
                // } else {                
                //     this.product_codes_multiple = [];
                // }
            }
        },
        async getListCategory() {
            const res = await store.dispatch('productStore/getListCategory');
            this.categories = res;
        },
        async getListBrands() {
            const res = await store.dispatch('productStore/getListBrands');
            this.brands = res;
        },
        async addQuickProduct() {
            const res = await store.dispatch('campaignStore/addProductOpenCampaign', {
                commission_rate_partner: this.commission_rate_quick,
                commission_rate_contributor: this.commission_rate_quick,
                product_codes: this.product_codes_multiple,
                start_time: moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                end_time: null,
            });
            if (!res || res.status === 500) {
                toastr.error(this.$t('msgCreateError'));
            } else if (res.status === 422) {
                let errors = res.response;
                Object.keys(errors).forEach(function(key) {
                    toastr.error(errors[key][0]);
                })
            } else if (res.status === 200) {
                toastr.success(this.$t('msgCreateSuccess'));
                this.clearDataAddQuick();
            }
        },
        clearDataAddQuick() {
            this.commission_rate_quick = '';
            this.product_codes_multiple = [];
            this.paginate.currentPage = 1;
            this.getDataCurrentTab();
        },
        clearSearch() {
            this.skuIdLocal = null;
            this.productNameLocal = null;
            this.categoryIdLocal = null;
            this.brandIdLocal = null;
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];
            this.paginate.currentPage = 1;
            this.searchData.skuId = null;
            this.searchData.productName = null;
            this.searchData.categoryId = null;
            this.searchData.brandId = null;
            this.product_codes_multiple = [];
            this.getDataCurrentTab();
            this.checkSelectedAll();
        },
        onSearchData() {            
            this.searchData.skuId = this.skuIdLocal;
            this.searchData.productName = this.productNameLocal;
            this.searchData.categoryId = this.categoryIdLocal;
            this.searchData.brandId = this.brandIdLocal;   
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];   
            this.paginate.currentPage = 1;                     
            this.getDataCurrentTab();
        },
        getDataCurrentTab() {            
            if (this.filters.tab === 2) {
                this.getListProductNotActiveOpenCampaign();
            } else {
                this.getListProductActiveOpenCampaign();
            }
        },
        async getListProductActiveOpenCampaign() {            
            const res = await store.dispatch('productStore/getListProductActiveOpenCampaign', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                skuId: this.searchData.skuId,
                productName: this.searchData.productName,
                categoryId: this.searchData.categoryId,
                brandId: this.searchData.brandId,
            });
            if (!res) {
                return;
            }
            this.productsActive = res.data;
            // this.checkedDisabledAll();
            this.totalProductActive = res.total;
            if (this.filters.tab == 1) {
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
            this.checkSelectedAll();
        },
        // checkedDisabledAll() {
        //     for (let index = 0; index < this.productsActive.length; index++) {
        //         const product = this.productsActive[index];
        //         if (product.open_campaign_product.end_time && product.open_campaign_product.end_time < this.now) {
        //             this.disabledAll = true;                            
        //             return;
        //         }
        //     }
        // },
        async getListProductNotActiveOpenCampaign() {
            const res = await store.dispatch('productStore/getListProductNotActiveOpenCampaign', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                skuId: this.searchData.skuId,
                productName: this.searchData.productName,
                categoryId: this.searchData.categoryId,
                brandId: this.searchData.brandId,
            });
            if (!res) {
                return;
            }
            this.productsNotActive = res.data;
            this.totalProductNotActive = res.total;
            if (this.filters.tab == 2) {
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
            this.checkSelectedAll();
        },
        showModalCreateOpenCampaign(productCode) { 
            store.dispatch('campaignStore/addProductCode', [productCode]);         
            this.$refs.ModalCreateOpenCampaign.show();
        },
        changeTab(tab){
            this.filters.tab = tab;
            this.product_codes_multiple = [],
            this.clearSearch();
        },
        showModalRemoveProduct(code, start_time, end_time){
            if (start_time > this.now) {
                this.contentRemove = i18n.global.t('contentConfirmRemoveProduct'); 
                this.typeStop = 'delete'; 
            } else {
                let endTimePlan = null;
                let time = moment().add(7, 'days').format('HH:mm DD/MM/YYYY');
                if (end_time && end_time < this.nowPlus7day)  {
                    return;
                }
                if (!end_time) {
                    endTimePlan = moment().add(7, 'days').format('HH:mm ' + i18n.global.t("date") + 'DD/MM/YYYY');
                } else {
                    let endTimeOrigin = moment(end_time);
                    if (moment().add(7, 'days') < endTimeOrigin) {
                        endTimePlan = moment().add(7, 'days').format('HH:mm' + i18n.global.t("date") + 'DD/MM/YYYY');
                    } else {
                        return;
                    }
                }     
                // this.contentRemove = i18n.global.t("contentStopCampaignStart") + endTimePlan + i18n.global.t('contentStopCampaignEnd');  
                this.contentRemove = this.$t('contentStopOpenCampaign', {time: time})
                this.typeStop = 'update';         
            }
            
            this.prdCode = code;
            this.$refs.ModalConfirm.show();
        },
        async removeProduct(){
            const res = await store.dispatch('campaignStore/removeProductOpenCampaign', {
                product_code: this.prdCode,
                type: this.typeStop,
            });  
            if (res && res.status) {
                toastr.success(res.message);
            } else {
                toastr.error('server error');
            }
            this.loadAllDataProduct();
        },
        loadAllDataProduct() {
            this.getListProductNotActiveOpenCampaign();
            this.getListProductActiveOpenCampaign();
        },
        async exportData(){
            const res = await store.dispatch('campaignStore/exportOpenCampaign', {
                skuId: this.searchData.skuId,
                productName: this.searchData.productName,
                categoryId: this.searchData.categoryId,
                brandId: this.searchData.brandId,
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        checkSelectedAll(){
            if (this.filters.tab == 2) {
                let checkedAll = true;
                this.productsNotActive.forEach(product => {
                    if(!this.product_codes_multiple.includes(product?.code)){
                        checkedAll = false;
                    }
                });
                this.checkAllTypeNotActive = checkedAll;
            } else {                
                let checkedAll = true;
                this.productsActive.forEach(product => {
                    if(!this.product_codes_multiple.includes(product?.product_code)){
                        checkedAll = false;
                    }
                });
                this.checkAllTypeActive = checkedAll;
            }
        }
    },
    watch: {
        'paginate.currentPage': function (newCurrentPage) {
            this.getDataCurrentTab();
        },
        'load_data_index': function (newStatus) {
            if (newStatus) {
                this.loadAllDataProduct();
                store.dispatch('campaignStore/loadDataLastUpdate', false);   
                this.product_codes_multiple = [];
            }
        },
        product_codes_multiple: {
            handler: function(newData) {                
                // if (this.filters.tab == 2) {
                //     if (newData && newData.length == this.productsNotActive.length && this.productsNotActive.length > 0) {                    
                //         this.checkAllTypeNotActive = true;
                //     } else {
                //         this.checkAllTypeNotActive = false;
                //     }
                // } else {
                //     if (newData && newData.length == this.productsActive.length && this.productsActive.length > 0) {                    
                //         this.checkAllTypeActive = true;
                //     } else {
                //         this.checkAllTypeActive = false;
                //     }
                // }
                this.checkSelectedAll();
                this.$forceUpdate();
            },
            deep: true
        },
        // messages: {
        //     handler: function(newData) {                
        //         console.log('newData', newData);
        //     },
        //     deep: true
        // },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/openCampaign.scss";
</style>