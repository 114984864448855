<template>
    <div>
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'CKEditorComponent',
    components: {
        ckeditor: Ckeditor,
    },
    data() {
        const editor = ClassicEditor;
        const editorData = this.ckeditorData ?? '';
        const editorConfig = {
            licenseKey: 'GPL',
            toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'subscript',
                'superscript',
                '|',
                'link',
                'bulletedList',
                'numberedList',
                'todoList',
                '|',
                'alignment',
                'blockQuote',
                'code',
                'codeBlock',
                '|',
                'imageUpload',
                'insertTable',
                'mediaEmbed',
                'undo',
                'redo',
                '|',
                'highlight',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                'horizontalLine',
                'pageBreak',
                'specialCharacters',
            ],
            image: {
                toolbar: [
                    'imageTextAlternative',
                    'imageStyle:inline',
                    'imageStyle:block',
                    'imageStyle:side',
                ],
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
            },
            mediaEmbed: {
                previewsInData: true,
            },
        }

        return {
            editor,
            editorData,
            editorConfig,
        };
    },
    computed: {
        ...mapGetters(['ckeditorData'])
    },
    watch: {
        ckeditorData: {
            handler: function(newValue) {
                this.editorData = newValue;
            },
            deep: true
        },
        editorData: {
            handler: function(newValue) {
                this.$store.dispatch('setCKEditorDataAfter', newValue);
            },
            deep: true
        },
    },
};
</script>