<template>
    <div id="unauthorized-page">
    </div>
</template>

<script>
export default {
    name: 'UnAuthorized',
    setup() {
        return {};
    },
    components: {},
    data() { 
        return {}
    },
    mounted(){},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>