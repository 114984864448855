<template>
    <slot v-if="checkPermission(this.keyPerm, this.actionPerm)"></slot>
</template>

<script>
import { checkPermission } from '@/utils/utils';
export default {
    name: "AppPermission",
    setup() {
        return { checkPermission };
    },
    props: ['keyPerm', 'actionPerm'],
}
</script>

<style scoped>
</style>
