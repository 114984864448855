<template>
    <div id="create-partner-page">
        <div class='container-fluid p-3'>
            <div class='row my-3'>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('partnerName') }}:<span class="text-danger">*</span></label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterPartnerName')" v-model="entry.name" :errors="(this.errors?.name ?? [])"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('birthday') }}:</label>
                            <div class='right'>
                                <InputDate :placeholder="$t('enterBirthday')" v-model="entry.birthday" :max="today" :errors="errors?.birthday ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('email') }}:<span class="text-danger">*</span></label>
                            <div class='right'>
                                <InputText :placeholder="entry?.email ? entry?.email : $t('enterCollaboratorEmail')" :errors="errors?.email ?? []" :disabled="true" v-if="entry.id"/>
                                <InputText :placeholder="$t('enterPartnerEmail')" v-model="entry.email" :errors="(this.errors?.email ?? [])" v-else/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('phone') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterPartnerPhoneNumber')" v-model="entry.phone" :errors="(this.errors?.phone ?? [])"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('taxCode') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterTaxCode')" v-model="entry.tax_code" :errors="(this.errors?.tax_code ?? [])"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('address') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterPartnerAddress')" v-model="entry.address" :errors="(this.errors?.address ?? [])"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('identification') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterIdentification')" v-model="entry.identification_code" :errors="(this.errors?.identification_code ?? [])"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('accountType') }}:<span class="text-danger">*</span></label>
                            <div class=''>
                                <InputSelect :placeholder="$t('selectAccountType')" :options="this.$constant.ALL_ACCOUNT_TYPE" v-model="entry.type" :errors="(this.errors?.type ?? [])"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex'>
                            <label class='left'>{{ $t('identificationFrontImage') }}:</label>
                            <div class='right'>
                                <InputFile :image="true" :modelValue="entry?.identification_card_front" @update:modalValue="changeIdentificationCardFront"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex'>
                            <label class='left'>{{ $t('identificationBackImage') }}:</label>
                            <div class='right'>
                                <InputFile :image="true" :modelValue="entry?.identification_card_back" @update:modalValue="changeIdentificationCardBack"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                        <div class="title">
                            {{ $t('paymentInfor') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('bank') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterBankInfor')" v-model="entry.bank_name" :errors="(this.errors?.bank_name ?? [])"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('branch') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterBranchName')" v-model="entry.bank_branch" :errors="(this.errors?.bank_branch ?? [])"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('accountNumber') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterAccountNumber')" v-model="entry.bank_account_number" :errors="(this.errors?.bank_account_number ?? [])"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('accountOwner') }}:</label>
                            <div class='right'>
                                <InputText :placeholder="$t('enterAccountOwner')" v-model="entry.bank_owner_name" :errors="(this.errors?.bank_owner_name ?? [])"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class='info mt-1 d-flex align-items-center'>
                            <label class='left'>{{ $t('paymentCycle') }}:</label>
                            <div class='right'>
                                <div class="d-flex justify-content-around">
                                    <InputRadio :label="$t('every2Weeks')" :value="this.$constant.PARTNER.payment_cycle_every_2_week" v-model="entry.payment_cycle" :modelValue="entry.payment_cycle" :name="'payment_cycle'"/>
                                    <InputRadio :label="$t('everyMonth')" :value="this.$constant.PARTNER.payment_cycle_every_month" v-model="entry.payment_cycle" :modelValue="entry.payment_cycle" :name="'payment_cycle'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center">
                        <button class="btn btn-outline-secondary mx-3" @click="cancel">
                            <span class="mx-2">
                                {{ $t('cancel') }}
                            </span>
                        </button>
                        <button class="btn btn-primary mx-3" @click="save">
                            <i class="fa fa-save mr-2"></i>
                            <span class="mx-2">
                                {{ $t('save') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm 
            :title="$t('confirm')"
            :content="$t('confirmCancelChangeData')"
            ref="ModalConfirm"
            @confirm="goBack"
        />
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import InputRadio from '@/components/Form/InputRadio.vue';
import GoBack from '@/components/Button/GoBack.vue';
import toastr from 'toastr';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import { isChangeData, clone } from '@/utils/utils';
import { mapGetters } from 'vuex';
import InputFile from '@/components/Form/InputFile.vue';
import moment from 'moment';
import InputDate from '@/components/Form/InputDate.vue'

export default {
    name: 'CreatePartner',
    setup() {
        return { isChangeData, clone };
    },
    components: {
        InputText,
        InputSelect,
        InputRadio,
        GoBack,
        ModalConfirm,
        InputFile,
        InputDate
    },
    data() {
        return {
            entry: {},
            normalEntry: {},
            errors: {},
            today: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
    },
    mounted(){
        const data = this.detail && Object.keys(this.detail).length != 0 ? this.detail : {
            name: '',
            email: '',
            phone: '',
            tax_code: '',
            address: '',
            type: 1,
            bank_name: '',
            bank_branch: '',
            bank_account_number: '',
            bank_owner_name: '',
            payment_cycle: this.$constant.PARTNER.payment_cycle_every_month,
            identification_card_front: '',
            identification_card_back: '',
            identification_code: '',
            birthday: ''
        };
        if(data.birthday){
            data.birthday = moment(data.birthday, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
        }
        this.entry = clone(data);
        this.normalEntry = clone(data);
    },
    computed: {
        ...mapGetters({
            detail: 'partners/getDetail',
        })
    },
    methods: {
        goBack(){
            this.$store.dispatch('partners/setDetail', {});
            this.$router.push({name: this.$constant.ROUTER.partnerName});
        },
        cancel(){
            if(isChangeData(this.entry, this.normalEntry)){
                this.$refs.ModalConfirm.show();
                return;
            }
            this.goBack();
        },
        async save(){
            let data = JSON.parse(JSON.stringify(this.entry));
            data.birthday = data.birthday ? moment(data.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') : null;
            let res;
            if(data?.id){
                res = await this.$store.dispatch('partners/updatePartner', data);
            }else{
                res = await this.$store.dispatch('partners/createPartner', data);
            }
            if(res?.error){
                if(res?.messages){
                    this.errors = res?.messages ?? {};
                }else{
                    const message = data?.id ? this.$t('msgUpdateError') : this.$t('msgCreateError');
                    toastr.error(message);
                }
                return;
            }else{
                const message = data?.id ? this.$t('msgUpdateSuccess') : this.$t('msgCreateSuccess');
                toastr.success(message);
                this.goBack();
            }
        },
        changeIdentificationCardFront(value){
            this.entry.identification_card_front = value;
        },
        changeIdentificationCardBack(value){
            this.entry.identification_card_back = value;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createPartner.scss";
</style>