<template>
    <div id="main-analytical-data-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row d-flex justify-content-between'>
                                <div class='col-xl-8 col-md-8 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>
                                    <!-- <Daterangepicker :placeholder="$t('timeOverview')" v-model="dateTimeSearch"
                                        :defaultDate="dateTimeSearch" class="mx-2" /> -->
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-4 col-md-4 col-sm-12 text-right'>
                                    {{ $t('lastUpdated') }} : {{ lastUpdated ? $filters.formatDateTime(lastUpdated) : null }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('mainAnalyticalData') }}</h4>
                                </div>
                            </div>
                            <ul class='nav nav-tabs' role='tablist'>
                                <li class='nav-item'>
                                    <a class='nav-link' role='tab'
                                        @click="changeChanel(null)" :class="this.channelId == null ? 'active' : ''">
                                        <span class='hidden-sm-up'></span>
                                        <span class='hidden-xs-down'>{{ $t('allSource') }}</span>
                                    </a>
                                </li>
                                <li class='nav-item' v-for="channel in channels" :key="channel.id">
                                    <a class='nav-link' role='tab'
                                        @click="changeChanel(channel.id)" :class="this.channelId == channel.id ? 'active' : ''">
                                        <span class='hidden-sm-up'> </span>
                                        <span class='hidden-xs-down'>{{ channel.name }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="row mt-3">
                                <div role="button" class="col-md-6 col-lg-3 col-xlg-3"
                                    @click="showChart($constant.PAGE.TYPE_CHART_SALES, $t('sales'))">
                                    <div class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('sales') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(amount_sale) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_amount_sale >= 0" class="text-success">▲{{
                                                    rate_amount_sale }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_amount_sale) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_PRODUCTS_SOLD, $t('productSold'))">
                                        <div class="box border">
                                            <label>{{ $t('productSold') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(products_sold) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_products_sold >= 0" class="text-success">▲{{
                                                    rate_products_sold }}%</span>
                                                <span v-else class="text-danger">&#9660; {{ $filters.formatNegativeNumber(rate_products_sold) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ORDER, $t('order'))">
                                        <div class="box border">
                                            <label>{{ $t('order') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(quantity_order) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_quantity_order >= 0" class="text-success">▲{{
                                                    rate_quantity_order }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_quantity_order) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_COMMISSION, $t('estCommission'))">
                                        <div class="box border">
                                            <label>{{ $t('estCommission') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(commission) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_commission >= 0" class="text-success">▲{{
                                                    rate_commission }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_commission) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ROI, 'ROI')">
                                        <div class="box border">
                                            <label>{{ $t('roi') }}</label>
                                            <h3 class="text-dark">{{ roi }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_roi >= 0" class="text-success">▲{{ rate_roi }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_roi) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER, $t('allBuyer'))">
                                        <div class="box border">
                                            <label>{{ $t('allBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(all_buyers) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_all_buyers >= 0" class="text-success">▲{{
                                                    rate_all_buyers }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_all_buyers) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER_NEW, $t('newBuyer'))">
                                        <div class="box border">
                                            <label>{{ $t('newBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(new_buyer) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_new_buyer >= 0" class="text-success">▲{{ rate_new_buyer
                                                    }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_new_buyer)}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <label>{{ nameChart }}</label>
                                    <LineChart :labelValues="labels" :datasetValues="datasets"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row' v-if="!channelId">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h4 class='card-title'>{{ $t('marketingSourceAnalysis') }}</h4>
                            <div class="d-flex justify-content-center">
                                <DoughnutChart :labelValues="this.doughnutLabels"
                                    :datasetValues="this.doughnutDatasets" v-if="this.have_data"/>
                                <div id="noData" v-if="!this.have_data">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center justify-content-between mb-1'>
                                <div>
                                    <h4 class='card-title'>{{ $t('productTop') }}</h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopProduct">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ $t('productInformation') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopProduct.field"
                                                    @onsort="sortTopProduct" :name="$t('sales')"
                                                    :field="'sum_amount'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopProduct.field"
                                                    @onsort="sortTopProduct" :name="$t('numberOfProductSold')"
                                                    :field="'sum_quantity'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopProduct.field"
                                                    @onsort="sortTopProduct" :name="$t('order')"
                                                    :field="'count_order'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopProduct.field"
                                                    @onsort="sortTopProduct" :name="$t('estCommission')"
                                                    :field="'sum_commission'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopProduct.field"
                                                    @onsort="sortTopProduct" :name="$t('roi')" :field="'roi'" />
                                            </th>
                                            <th scope="col">{{ $t('totalBuyer') }}</th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable" v-if="this.productsTop?.length > 0">
                                        <tr v-for="(productTop, index) in productsTop" :key="index">
                                            <td>
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img :src="productTop.product.main_image" />
                                                    </div>
                                                    <div class="product mx-1">
                                                        <p>
                                                            <a target="_blank"
                                                                :href="urlPharmaProduct(productTop.product.slug)">
                                                                {{ productTop.product.name ?? null }}
                                                            </a>
                                                        </p>
                                                        <p>
                                                            {{ $t('skuId') }}: {{
                                                            productTop.product.product_variant_max_price.sku ?? null }}
                                                        </p>
                                                        <p>
                                                            {{ $t('price') }}: {{
                                                                numberFormat(productTop.product.product_variant_max_price.price
                                                            ?? 0 ) }}
                                                        </p>

                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ numberFormat(productTop.sum_amount) }}</td>
                                            <td>{{ numberFormat(productTop.sum_quantity) }}</td>
                                            <td>{{ numberFormat(productTop.count_order) }}</td>
                                            <td>{{ numberFormat(productTop.sum_commission) }}</td>
                                            <td>{{ productTop.roi }}</td>
                                            <td>{{ numberFormat(productTop.count_buyer) }}</td>
                                            <td>{{ numberFormat(productTop.count_buyer_new) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.productsTop?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center justify-content-between mb-1'>
                                <div>
                                    <h4 class='card-title'>
                                        <span v-if="this.user && this.user.type == this.$constant.PARTNER.type_partner">
                                            {{ $t('topContributor') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('partnerTop') }}
                                        </span>
                                    </h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopPartner">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ this.user?.type == this.$constant.PERMISSIONS.TYPE_PARTNER ? $t('contributorInfo') : $t('partnerInfo') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopPartner.field"
                                                    @onsort="sortTopPartner" :name="$t('sales')"
                                                    :field="'sum_amount'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopPartner.field"
                                                    @onsort="sortTopPartner" :name="$t('itemsForSale')"
                                                    :field="'count_product'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopPartner.field"
                                                    @onsort="sortTopPartner" :name="$t('order')"
                                                    :field="'count_order'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopPartner.field"
                                                    @onsort="sortTopPartner" :name="$t('estCommission')"
                                                    :field="'sum_commission'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataTopPartner.field"
                                                    @onsort="sortTopPartner" :name="$t('roi')" :field="'roi'" />
                                            </th>
                                            <th scope="col">{{ $t('totalBuyer') }}</th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable" v-if="this.topPartners?.length > 0">
                                        <tr v-for="(topPartner, index ) in topPartners" :key="index">
                                            <td>
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                <span class="text-primary pe-auto" @click="topPartner.name ? linkToPartner(topPartner.name) : ''">
                                                    {{ topPartner.name }}
                                                </span>
                                            </td>
                                            <td>{{ numberFormat(topPartner.sum_amount) }}</td>
                                            <td>{{ numberFormat(topPartner.count_product) }}</td>
                                            <td>{{ numberFormat(topPartner.count_order) }}</td>
                                            <td>{{ numberFormat(topPartner.sum_commission) }}</td>
                                            <td>{{ topPartner.roi }}</td>
                                            <td>{{ numberFormat(topPartner.count_buyer) }}</td>
                                            <td>{{ numberFormat(topPartner.count_buyer_new) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.topPartners?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import i18n from '@/locales/i18nHelper'
import store from '@/store';
import TableSortField from '@/components/TableSortField.vue';
import { mapGetters } from 'vuex';
import InputMonth from '@/components/Form/InputMonth.vue'

export default {
    name: 'MainAnalyticalData',
    setup() {
        return { numberFormat, checkPermission };
    },
    components: {
        Daterangepicker,
        Loading,
        LineChart,
        DoughnutChart,
        TableSortField,
        InputMonth
    },
    created() {
        this.getChannels();
        this.getDataForChannelMainAnalysis(true);
    },
    mounted() {
        // this.getDataBarChartForChannel();
        this.getDataRoundChartForChannel();
        this.getTopProductsForChannel(true);
    },
    data() {
        return {
            channels: [],
            dateTimeSearch: moment().format('YYYY-MM'),
            channelId: null,
            all_buyers: null,
            amount_sale: null,
            commission: null,
            new_buyer: null,
            products_sold: null,
            quantity_order: null,
            roi: null,
            rate_channelId: null,
            rate_all_buyers: null,
            rate_amount_sale: null,
            rate_commission: null,
            rate_new_buyer: null,
            rate_products_sold: null,
            rate_quantity_order: null,
            rate_roi: null,
            typeSrc: this.$constant.PAGE.TYPE_CHART_SALES,
            labels: [],
            datasets: [],
            nameChart: i18n.global.t('sales'),
            productsTop: [],
            topPartners: [],
            sortDataTopPartner: {
                field: '',
                direction: '',
            },
            sortDataTopProduct: {
                field: '',
                direction: '',
            },
            isLoading: false,
            doughnutLabels: [],
            doughnutDatasets: [],
            isCallMain: false,
            lastUpdated: null,
            have_data: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_MAIN_ANALYTICAL_DATA, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    watch: {
        dateTimeSearch() {
            this.getDataForChannelMainAnalysis(true);
            // this.getDataBarChartForChannel();
            this.getChannels();
            this.getTopProductsForChannel(true);
            // this.getTopPartners();
            this.getDataRoundChartForChannel();
        }
    },
    methods: {
        goToDetailPartner(partnerId) {
            this.$router.push({ name: this.$constant.ROUTER.partnerName, query: { partner_id: partnerId } });
        },
        async getTopPartners() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getTopPartners', {
                dateTimeSearch: this.dateTimeSearch,
                channelId: this.channelId,
                ...this.sortDataTopPartner
            });
            if (res) {
                this.topPartners = res;
            }
        },
        async getTopProductsForChannel(statusGetPartner) {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getTopProductsForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                channelId: this.channelId,
                ...this.sortDataTopProduct
            });
            if (res) {
                this.productsTop = res;
            }
            if (statusGetPartner) {
                this.getTopPartners();
            }
        },
        async getDataRoundChartForChannel() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataRoundChartForChannel', {
                dateTimeSearch: this.dateTimeSearch,
            });

            if (res) {
                this.doughnutLabels = res.doughnutLabels;
                this.doughnutDatasets = res.doughnutDatasets;
                this.have_data = res?.have_data;
            }
        },
        showChart(typeSrc, nameChart) {
            this.typeSrc = typeSrc;
            this.nameChart = nameChart;
            this.getDataBarChartForChannel();
        },
        async getDataBarChartForChannel() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataBarChartForChannel', {
                channelId: this.channelId,
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc
            });
            if (res) {
                this.datasets = res.datasets
                this.labels = res.labels
            }
        },
        changeChanel(channelId) {
            this.channelId = channelId;
            this.sortDataTopPartner = {
                field: '',
                direction: '',
            }
            this.sortDataTopProduct = {
                field: '',
                direction: '',
            }
            this.getDataForChannelMainAnalysis();
            this.getDataBarChartForChannel();
            this.getTopProductsForChannel(true);
        },
        async getChannels() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannelHasRevenue', {
                dateTimeSearch: this.dateTimeSearch,
            });
            if (res) {
                this.channels = res;
            }
        },
        async getDataForChannelMainAnalysis(callDataBart = false) {
            if (!this.dateTimeSearch || this.isCallMain) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataForChannelMainAnalysis', {
                channelId: this.channelId,
                dateTimeSearch: this.dateTimeSearch,
            });
            if (res) {
                this.all_buyers = res.all_buyers;
                this.amount_sale = res.amount_sale;
                this.commission = res.commission;
                this.new_buyer = res.new_buyer;
                this.products_sold = res.products_sold;
                this.quantity_order = res.quantity_order;
                this.roi = res.roi;
                this.rate_channelId = res.rate_channelId;
                this.rate_all_buyers = res.rate_all_buyers;
                this.rate_amount_sale = res.rate_amount_sale;
                this.rate_commission = res.rate_commission;
                this.rate_new_buyer = res.rate_new_buyer;
                this.rate_products_sold = res.rate_products_sold;
                this.rate_quantity_order = res.rate_quantity_order;
                this.rate_roi = res.rate_roi;
                this.lastUpdated = res.last_updated
            }
            this.isCallMain = false;
            if (callDataBart) {
                this.getDataBarChartForChannel();
            }
        },
        sortTopPartner(field, direction) {
            this.sortDataTopPartner.field = field;
            this.sortDataTopPartner.direction = direction;
            this.getTopPartners();
        },
        sortTopProduct(field, direction) {
            this.sortDataTopProduct.field = field;
            this.sortDataTopProduct.direction = direction;
            this.getTopProductsForChannel();
        },
        clearFilter() {
            this.sortDataTopPartner = {
                field: '',
                direction: '',
            }
            this.sortDataTopProduct = {
                field: '',
                direction: '',
            }
            this.channelId = null;
            this.dateTimeSearch = moment().format('YYYY-MM');
            this.getDataRoundChartForChannel();
            this.getTopProductsForChannel(true);
            this.getDataForChannelMainAnalysis(true);
            this.getChannels();
        },
        linkToPartner(name) {
            if (this.user && this.user.type == this.$constant.PARTNER.type_partner) {
                this.$router.push({ name: this.$constant.ROUTER.collaboratorsName, query: { name: name } });
            } else {
                this.$router.push({ name: this.$constant.ROUTER.partnerName, query: { name: name } });
            }
        },
        async exportTopProduct(){
            
            const res = await this.$store.dispatch('performanceStore/exportTopProductMainAnalysis', {
                dateTimeSearch: this.dateTimeSearch,
                channelId: this.channelId,
                ...this.sortDataTopProduct
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        async exportTopPartner(){
            
            const res = await this.$store.dispatch('performanceStore/exportTopPartnerMainAnalysis', {
                dateTimeSearch: this.dateTimeSearch,
                channelId: this.channelId,
                ...this.sortDataTopPartner
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mainAnalyticalData.scss";
</style>