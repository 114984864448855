<template>
    <div id="create-target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row p-3 border-block'>
                <div class='col-lg-12'>
                    <div class="card">
                        <ul class='nav nav-tabs' role='tablist'>
                            <li class='nav-item'>
                                <a class='nav-link' :class="tab === 1 ? 'active' : ''" data-bs-toggle='tab'
                                    role='button' @click="changeTab(1)">
                                    <span class='hidden-sm-up'></span>
                                    <span class='hidden-xs-down'>{{ $t('campaign') }}</span>
                                </a>
                            </li>
                            <li class='nav-item'>
                                <a class='nav-link' :class="tab === 2 ? 'active' : ''" data-bs-toggle='tab'
                                    role='button' @click="changeTab(2)">
                                    <span class='hidden-sm-up'></span>
                                    <span class='hidden-xs-down'>{{ $t('performance') }}</span>
                                </a>
                            </li>
                        </ul>
                        <template v-if="tab == 1">
                            <div class="mt-3 d-flex justify-content-between">
                                <div>{{ $t('campaignCode') }}: {{ info.code }}</div>
                                <div>
                                    {{ $t('campaignStatus') }}:
                                    <span
                                        :class="getClassForTargetCampaignStatus($filters.formatStatusCampaignPartner(info.status, info.start_time, info.end_time))">
                                        {{ $filters.formatStatusCampaignPartner(info.status, info.start_time,
                                        info.end_time) }}
                                    </span>
                                </div>
                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                    <button class="btn btn-primary" @click="campaignEdit()" role='button'
                                        v-if="!listStatusCannotEdit.includes($filters.formatStatusCampaignPartner(info.status, info.start_time, info.end_time))">
                                        <i class="fa fa-edit mx-2"></i>
                                        <span>
                                            {{ $t('campaignEdit') }}
                                        </span>
                                    </button>
                                </AppPermission>
                            </div>
                        </template>

                        <template v-else>
                            <div class="mt-3 d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <span v-html="$t('timeSeeData')"></span>
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class="text-right">
                                    <span>
                                        {{ $t('lastUpdated') }}:  {{ $filters.formatDateTime(lastUpdated) }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="tab === 1">
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <div class="d-flex justify-content-between">
                                <h4 class='card-title mb-2'>{{ $t('infoPartner') }}</h4>
                            </div>
                            <div class="table-responsive" v-if="partners.length">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('partnerName') }}</th>
                                            <th scope="col">{{ $t('numberOfCollaborators') }}</th>
                                            <th scope="col">{{ $t('numberOfClicked') }}</th>
                                            <th scope="col">{{ $t('numberOfOrders') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(partner, index) in partners" :key="index">
                                            <tr>
                                                <td>
                                                    <p>{{ partner.name }}</p>
                                                </td>
                                                <td>{{ partner.affiliate_partners_count ?? 0 }}</td>
                                                <td>{{ partner.quantity_click ?? 0 }}</td>
                                                <td>{{ partner.quantity_order ?? 0 }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <h4 class='card-title mb-2'>{{ $t('infoProduct') }}</h4>
                            <div class="table-responsive table-products">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('product') }}</th>
                                            <th scope="col">{{ $t('price') }}</th>
                                            <th scope="col">{{ $t('percentCommission') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(prdItem, index) in products" :key="index">
                                            <tr>
                                                <td>
                                                    <div class="info-products d-flex">
                                                        <div class="image">
                                                            <img :src="prdItem.product.main_image"
                                                                v-if="prdItem?.product?.main_image" />
                                                        </div>
                                                        <div class="product mx-1">
                                                            <p>{{ prdItem.product.name ?? null }}</p>
                                                            <p>
                                                                {{ $t('skuIdCode') }}: {{
                                                                prdItem.product.product_variant_max_price.sku ?? null }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span v-if="prdItem.product.product_variant_max_price">{{
                                                        $filters.formatPrice(prdItem.product.product_variant_max_price.price)
                                                        }}</span>
                                                    <span v-else>0</span>
                                                </td>
                                                <td>
                                                    {{ prdItem.commission_rate_partner + '%' }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='row p-3 border-block'>
                    <div class='col-lg-12'>
                        <div class="card">
                            <h4 class='card-title mb-2'>{{ $t('infoInvitation') }}</h4>
                            <div class="mx-0 p-3">
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('campaignName') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <span>{{ info.name }}</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('campaignTime') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <div class='row'>
                                            <div class='col-lg-6 col-md-12 col-sm-12'>
                                                <span>
                                                    {{ $filters.formatStartTimeAndEndTime(info.start_time, info.end_time
                                                    ) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 control-label col-form-label">
                                        {{ $t('message') }}:
                                    </label>
                                    <div class="col-sm-10">
                                        <span>
                                            {{ info.message }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <DetailCampaignTargetAnalysis :dateTimeSearch="dateTimeSearch" />
            </template>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import { numberFormat, getClassForTargetCampaignStatus, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import store from "@/store";
import DetailCampaignTargetAnalysis from '../Performance/DetailCampaignTargetAnalysis.vue'
import Daterangepicker from '@/components/Daterangepicker.vue'
import InputMonth from '@/components/Form/InputMonth.vue'
import AppPermission from '@/components/AppPermission.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'DetailTargetCampaign',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus, checkPermission };
    },
    components: {
        Loading,
        DetailCampaignTargetAnalysis,
        Daterangepicker,
        InputMonth,
        AppPermission
    },
    data() {
        return {
            currentId: null, 
            partners: [],
            dateTimeSearch: moment().format('YYYY-MM'),
            dateTimeSearchDefault: moment().format('YYYY-MM'),
            tab: 1,
            info: {
                name: null,
                start_time: null,
                end_time: null,
                message: null,
                status: null,
                code: null,
            },
            listStatusCannotEdit: [
                this.$t('campaignStatusClose'),
                this.$t('campaignEnd'),
            ],
            lastUpdated: null,
        }
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        }),
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    created() {
        this.getIdDetail();
        this.getDetailCampaign();
    },
    methods: {
        clearFilter(){
            if(this.dateTimeSearchDefault != this.dateTimeSearch){
                this.dateTimeSearch = moment().format('YYYY-MM');
                return;
            }
            this.$store.dispatch('setClearFilter', true);
        },
        campaignEdit() {            
            this.$router.push({name: this.$constant.ROUTER.editTargetCampaignName, query: {id: this.currentId}});
        },
        async getIdDetail() {            
            this.currentId = this.$route.query.id ?? null;
            this.tab = this.$route.query.tab == 'performance' ? 2 : 1;
        },
        changeTab(tab) {
            this.tab = tab;
        },
        async getDetailCampaign() {
            const res = await store.dispatch('campaignStore/getDetailCampaign', {id: this.currentId,});
            if (!res || !res.code) {
                return;
            }            
            this.partners = res.target_campaign_partners;
            this.products = res.target_campaign_products;
            this.info.name = res.name;
            this.info.start_time = res.start_time;
            this.info.end_time = res.end_time;
            this.info.message = res.messages;
            this.info.status = res.status;
            this.info.code = res.code;
            this.lastUpdated = res.report_order_last_update ? res.report_order_last_update.updated_at : null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>